import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// All Pages
import Index from './pages/index';
import AboutUs from './pages/about';
import BlogDetails from './pages/blog-details';
import BlogListSidebar from './pages/blog-list-sidebar';
import Booking from './pages/booking';
import ContactUs from './pages/contact-1';
import Faq1 from './pages/faq-1';
import Services from './pages/service-1';
import ThankYou from './pages/thank-you';
import ThankYouEmail from './pages/thank-you-email';
import Error from './pages/error-404';
import TermsAndConditions from './pages/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy';
import CancellationPolicy from './pages/cancellation-policy';
import RefundPolicy from './pages/refund-policy';
import MechanicLogin from './pages/mechanic-login';
import MechanicDashboard from './pages/mechanic-dashboard';
import PartnerLogin from './pages/partner-login';
import PartnerDashboard from './pages/partner-dashboard';
import BookingDetails from './pages/booking-details';
import Login from './pages/login';
import Register from './pages/register';
import BookingHistory from './pages/booking-history';

class Markup extends Component {
  render() {
    // Extract common props to pass to all route components
    const commonProps = {
      isMobileBrowser: this.props.isMobileBrowser,
      isMobileApp: this.props.isMobileApp,
      citiesModal: this.props.citiesModal,
      setCitiesModal: this.props.setCitiesModal,
      openModal: this.props.openModal,
      setOpenModal: this.props.setOpenModal,
      user: this.props.user,
      setUser: this.props.setUser,
      userVehicles: this.props.userVehicles,
      setUserVehicles: this.props.setUserVehicles,
      setShowChatBot: this.props.setShowChatBot,
    };

    return (
      <>
        <Switch>
          <Route
            path='/'
            exact
            render={(props) => <Index {...props} {...commonProps} />}
          />
          <Route
            path='/city/:cityName'
            exact
            render={(routeProps) => (
              <Index
                {...routeProps}
                cityKey={routeProps.match.params.cityName}
                {...commonProps}
              />
            )}
          />
          <Route
            path='/about'
            exact
            render={(props) => <AboutUs {...props} {...commonProps} />}
          />
          <Route
            path='/blog-details/:slug'
            exact
            render={(props) => (
              <BlogDetails {...props} {...commonProps} />
            )}
          />
          <Route
            path='/blogs'
            exact
            render={(props) => (
              <BlogListSidebar {...props} {...commonProps} />
            )}
          />
          <Route
            path='/booking'
            exact
            render={(props) => <Services {...props} {...commonProps} />}
          />
          <Route
            path='/checkout'
            exact
            render={(props) => <Booking {...props} {...commonProps} />}
          />
          <Route
            path='/contact'
            exact
            render={(props) => <ContactUs {...props} {...commonProps} />}
          />
          <Route
            path='/faq'
            exact
            render={(props) => <Faq1 {...props} {...commonProps} />}
          />
          <Route
            path='/service'
            exact
            render={(props) => <Services {...props} {...commonProps} />}
          />
          <Route
            path='/thank-you'
            exact
            render={(props) => <ThankYou {...props} {...commonProps} />}
          />
          <Route
            path='/thank-you-email'
            exact
            render={(props) => (
              <ThankYouEmail {...props} {...commonProps} />
            )}
          />
          <Route
            path='/terms-and-conditions'
            exact
            render={(props) => (
              <TermsAndConditions {...props} {...commonProps} />
            )}
          />
          <Route
            path='/privacy-policy'
            exact
            render={(props) => (
              <PrivacyPolicy {...props} {...commonProps} />
            )}
          />
          <Route
            path='/cancellation-policy'
            exact
            render={(props) => (
              <CancellationPolicy {...props} {...commonProps} />
            )}
          />
          <Route
            path='/refund-policy'
            exact
            render={(props) => (
              <RefundPolicy {...props} {...commonProps} />
            )}
          />
          <Route
            path='/mechanic-login'
            exact
            render={(props) => (
              <MechanicLogin {...props} {...commonProps} />
            )}
          />
          <Route
            path='/mechanic-dashboard'
            exact
            render={(props) => (
              <MechanicDashboard {...props} {...commonProps} />
            )}
          />
          <Route
            path='/partner-login'
            exact
            render={(props) => (
              <PartnerLogin {...props} {...commonProps} />
            )}
          />
          <Route
            path='/login'
            exact
            render={(props) => <Login {...props} {...commonProps} />}
          />
          <Route
            path='/register'
            exact
            render={(props) => <Register {...props} {...commonProps} />}
          />
          <Route
            path='/partner-dashboard'
            exact
            render={(props) => (
              <PartnerDashboard {...props} {...commonProps} />
            )}
          />
          <Route
            path='/booking-history'
            exact
            render={(props) => (
              <BookingHistory {...props} {...commonProps} />
            )}
          />
          <Route
            path='/booking-details'
            exact
            render={(props) => (
              <BookingDetails {...props} {...commonProps} />
            )}
          />
          <Route
            path='/error-404'
            exact
            render={(props) => <Error {...props} {...commonProps} />}
          />
          <Route component={Error} />
        </Switch>

        <PageScrollTop />
        {/* <BackToTop /> */}
      </>
    );
  }
}

export default Markup;
