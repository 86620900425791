import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import bg2 from "../../images/background/bg1.png";

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ScheduleServiceSection from "../elements/schedule-service";
import PricingTableSection from "../elements/pricing-table";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class CancellationPolicy extends Component{

	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}
	
	render(){

		const { isClient } = this.state;

		return (
			<>
				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={ isClient ? { backgroundImage: "url("+bnrImg+")" } : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Cancellation Policy</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Cancellation Policy</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white" style={ isClient ? { backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat" } : {} }>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h4 className="title-ext text-primary">Cancellation Policy</h4>
									<p>
                    At RIDE N REPAIR, we understand that plans can change and cancellations may be necessary. The following Cancellation Policy outlines the conditions under which customers may cancel their orders and the associated fees.

                    Timeframe for Cancellation Customers may cancel their orders up to 1 Hr prior to the scheduled service date. Cancellations made after this timeframe may result in a cancellation fee.
                    Cancellation Fees If a customer cancels their order after the timeframe, a cancellation fee of Rs 199 will apply. The cancellation fee is intended to cover the costs associated with holding a reservation for the customer.
                    No-Shows Customers who do not show up for their scheduled service and do not provide prior notice of cancellation will be charged the full fee for the service.
                    Contact Information For questions or concerns about this Cancellation Policy or to cancel an order, please contact us at 8031406663 or info@ridenrepair.com

                    This Cancellation Policy is effective as of 6 Feb 2023.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default CancellationPolicy;