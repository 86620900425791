import React, { Component } from 'react';
import '../../css/mechanic-entry-form.css';
import { cities } from '../elements/cities-data';
import axios from "axios";

class MechanicEntryForm extends Component {
  constructor() {
    super();
    this.state = {
      city: 'bengaluru', // Default city
      name: '',
      phone: '',
      latitude: '',
      longitude: '',
      username: '',
      password: '',
      response: null,
      is_worker: false,
      mechanics_data: [],
      filtered_mechanics: [],
      garage_owner: '',
      works_on_cars: false,
      works_on_bikes: false,
      services_car_service_types: [],
      services_car_doorstep: false,
      services_car_pick_drop: false,
      services_bike_service_types: [],
      services_bike_doorstep: false,
      services_bike_pick_drop: false,
      // Service options
      car_service_options: [
        'Jump Start',
        'Puncture',
        'Running Repair',
        'Basic Service',
        'Standard Service',
        'Comprehensive Service'
      ],
      bike_service_options: [
        'Jump Start',
        'Puncture',
        'Running Repair',
        'General Service',
        'General Service with Engine Oil'
      ],
      // Select All flags
      selectAllCarServices: false,
      selectAllBikeServices: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount = () => {
    this.fetchMechanicsData();
  }

  fetchMechanicsData = () => {
    axios
      .get('https://ridenrepair.com/auto/get_all_mechanics')
      .then((response) => {
        const sortedData = response.data.sort((a, b) => a.city.localeCompare(b.city));
        this.setState({ mechanics_data: sortedData });
    })
      .catch((error) => {
        console.error('Error fetching leads data:', error);
    });
  }

  handleInputChange(event) {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value.trim() }, () => {
        if ((name === "is_worker" && value === "yes") || name === "city") {
          this.filterMechanicsByCity();
        }
        if (name === "is_worker" && value === "no") {
          this.setState({ filtered_mechanics: [], garage_owner: '' });
        }
      });
    }
  }

  handleSelectAllCarServices = (event) => {
    const checked = event.target.checked;
    this.setState({
      selectAllCarServices: checked,
      services_car_service_types: checked ? [...this.state.car_service_options] : [],
    });
  }

  handleCarServiceCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let { services_car_service_types } = this.state;
    if (checked) {
      services_car_service_types.push(value);
    } else {
      services_car_service_types = services_car_service_types.filter(service => service !== value);
    }
    this.setState({
      services_car_service_types,
      selectAllCarServices: services_car_service_types.length === this.state.car_service_options.length,
    });
  }

  handleSelectAllBikeServices = (event) => {
    const checked = event.target.checked;
    this.setState({
      selectAllBikeServices: checked,
      services_bike_service_types: checked ? [...this.state.bike_service_options] : [],
    });
  }

  handleBikeServiceCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let { services_bike_service_types } = this.state;
    if (checked) {
      services_bike_service_types.push(value);
    } else {
      services_bike_service_types = services_bike_service_types.filter(service => service !== value);
    }
    this.setState({
      services_bike_service_types,
      selectAllBikeServices: services_bike_service_types.length === this.state.bike_service_options.length,
    });
  }

  filterMechanicsByCity() {
    const { city, mechanics_data } = this.state;
    const filtered_mechanics = mechanics_data.filter(mechanic => mechanic.city === city);
    this.setState({ filtered_mechanics });
  }

  handleSubmit = async () => {
    const {
      city, name, phone, latitude, longitude, is_worker, garage_owner,
      works_on_cars, works_on_bikes,
      services_car_service_types, services_car_doorstep, services_car_pick_drop,
      services_bike_service_types, services_bike_doorstep, services_bike_pick_drop
    } = this.state;

    // Validation
    const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    const isValidPhone = phoneRegex.test(phone);
    if (!isValidPhone || latitude ==='' || longitude === '') {
      return;
    }

    if (!works_on_cars && !works_on_bikes) {
      return;
    }
    
    const username = phone.trim();
    const password = `${name.split(' ')[0].toLowerCase()}@${Math.floor(Math.random() * 1000)}`;

    // Send a POST request to your Node.js endpoint
    try {
      const response = await fetch('https://www.ridenrepair.com/api/add_mechanic', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          city,
          name,
          phone,
          latitude,
          longitude,
          password,
          is_worker,
          garage_owner,
          works_on_cars,
          works_on_bikes,
          services_car_service_types: services_car_service_types.join(','),
          services_car_doorstep: services_car_doorstep ? 'yes' : 'no',
          services_car_pick_drop: services_car_pick_drop ? 'yes' : 'no',
          services_bike_service_types: services_bike_service_types.join(','),
          services_bike_doorstep: services_bike_doorstep ? 'yes' : 'no',
          services_bike_pick_drop: services_bike_pick_drop ? 'yes' : 'no',
        }),
      });

      if (response.ok) {
        this.setState({ response: { 'username': username, 'password': password } });
      } else {
        console.log('Unable to add mechanic');
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const sortedCities = cities.sort((a, b) => a.name.localeCompare(b.name));
    const { is_worker, garage_owner, city } = this.state;

    return  (
      <div className="mechanic-entry-form">
        <h4>Mechanic Entry Form</h4>
        <div className="mechanic-entry-form-row">
          <div className="mechanic-entry-form-group">
            <label htmlFor="city">City:</label>
            <select name="city" id="city" value={city} onChange={this.handleInputChange}>
              {sortedCities.map((city) => (
                <option key={city.key} value={city.key}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
          {this.state.filtered_mechanics.length > 0 && this.state.is_worker && 
            <div className="mechanic-entry-form-group">
              <label htmlFor="garage_owner">Garage Owner: </label>
              <select name="garage_owner" id="garage_owner" value={garage_owner} onChange={this.handleInputChange}>
              <option value="" disabled>Select Mechanic</option>
              {this.state.filtered_mechanics.map(mechanic => (
                <option key={mechanic.phone} value={mechanic.phone}>
                  {mechanic.name}
                </option>
              ))}
              </select>
            </div>
          }
          <div className="mechanic-entry-form-group">
            <label htmlFor="is_worker">Is Worker:</label>
            <select name="is_worker" id="is_worker" value={is_worker || "no"} onChange={this.handleInputChange}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
        </div>
        <div className="mechanic-entry-form-row">
          <div className="mechanic-entry-form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" name="name" id="name" onChange={this.handleInputChange} />
          </div>
          <div className="mechanic-entry-form-group">
            <label htmlFor="phone">Phone:</label>
            <input type="number" name="phone" id="phone" onChange={this.handleInputChange} />
          </div>
        </div>
        <div className="mechanic-entry-form-row">
          <div className="mechanic-entry-form-group">
            <label htmlFor="latitude">Latitude:</label>
            <input type="number" name="latitude" id="latitude" onChange={this.handleInputChange} />
          </div>
          <div className="mechanic-entry-form-group">
            <label htmlFor="longitude">Longitude:</label>
            <input type="number" name="longitude" id="longitude" onChange={this.handleInputChange} />
          </div>
        </div>

        <div className="mechanic-entry-form-section">
          <h5>Service Capabilities</h5>

          <div className="mechanic-entry-form-group">
            <label>
              <input
                type="checkbox"
                name="works_on_cars"
                checked={this.state.works_on_cars}
                onChange={this.handleInputChange}
              />
              Works on Cars
            </label>
          </div>

          {this.state.works_on_cars && (
            <div className="mechanic-entry-form-subsection">
              <div className="mechanic-entry-form-group">
                <label>Car Service Types:</label>
                <div className="checkbox-group">
                  <div className="checkbox-option">
                    <label>
                      <input
                        type="checkbox"
                        name="selectAllCarServices"
                        checked={this.state.selectAllCarServices}
                        onChange={this.handleSelectAllCarServices}
                      />
                      Select All
                    </label>
                  </div>
                  {this.state.car_service_options.map((service) => (
                    <div key={service} className="checkbox-option">
                      <label>
                        <input
                          type="checkbox"
                          name="services_car_service_types"
                          value={service}
                          checked={this.state.services_car_service_types.includes(service)}
                          onChange={this.handleCarServiceCheckboxChange}
                        />
                        {service}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mechanic-entry-form-group">
                <label>
                  <input
                    type="checkbox"
                    name="services_car_doorstep"
                    checked={this.state.services_car_doorstep}
                    onChange={this.handleInputChange}
                  />
                  Offers Doorstep Service for Cars
                </label>
              </div>
              <div className="mechanic-entry-form-group">
                <label>
                  <input
                    type="checkbox"
                    name="services_car_pick_drop"
                    checked={this.state.services_car_pick_drop}
                    onChange={this.handleInputChange}
                  />
                  Offers Pick/Drop Service for Cars
                </label>
              </div>
            </div>
          )}

          <div className="mechanic-entry-form-group">
            <label>
              <input
                type="checkbox"
                name="works_on_bikes"
                checked={this.state.works_on_bikes}
                onChange={this.handleInputChange}
              />
              Works on Bikes
            </label>
          </div>

          {this.state.works_on_bikes && (
            <div className="mechanic-entry-form-subsection">
              <div className="mechanic-entry-form-group">
                <label>Bike Service Types:</label>
                <div className="checkbox-group">
                  <div className="checkbox-option">
                    <label>
                      <input
                        type="checkbox"
                        name="selectAllBikeServices"
                        checked={this.state.selectAllBikeServices}
                        onChange={this.handleSelectAllBikeServices}
                      />
                      Select All
                    </label>
                  </div>
                  {this.state.bike_service_options.map((service) => (
                    <div key={service} className="checkbox-option">
                      <label>
                        <input
                          type="checkbox"
                          name="services_bike_service_types"
                          value={service}
                          checked={this.state.services_bike_service_types.includes(service)}
                          onChange={this.handleBikeServiceCheckboxChange}
                        />
                        {service}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mechanic-entry-form-group">
                <label>
                  <input
                    type="checkbox"
                    name="services_bike_doorstep"
                    checked={this.state.services_bike_doorstep}
                    onChange={this.handleInputChange}
                  />
                  Offers Doorstep Service for Bikes
                </label>
              </div>
              <div className="mechanic-entry-form-group">
                <label>
                  <input
                    type="checkbox"
                    name="services_bike_pick_drop"
                    checked={this.state.services_bike_pick_drop}
                    onChange={this.handleInputChange}
                  />
                  Offers Pick/Drop Service for Bikes
                </label>
              </div>
            </div>
          )}
        </div>

        <button onClick={this.handleSubmit}>Add Mechanic</button>
        {this.state.response && (
          <div className="response">
            <p>Username: {this.state.response.username}</p>
            <p>Password: {this.state.response.password}</p>
          </div>
        )}
      </div>
    );
  }
}

export default MechanicEntryForm;
