import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import axios from "axios";
import '../../../src/Calendar.scss';
import Places from '../elements/places';
import smoothscroll from 'smoothscroll-polyfill';


class PartnerDashboard extends Component {
	dateformat = null;

	state = {
    partner_phone: '',
    partner_email: '',
    vehicle_type: '',
    vehicle_category: '',
    vehicle_name: '',
    vehicle_year: '',
    vehicle_mileage: '',
    selected_service: '',
    location_address: '',
		city: '',
		state: '',
		zip: '',
    latitude: '',
    longitude: '',
    home_address: '',
    addtnl_details: '',
    schedule_later: false,
    warehouse: [
      { name: '5k store - Sector 67, Noida' },
    ],
    vehicle_categories: [
      { name: 'Less than 200cc' },
      { name: 'Greater than 200cc' },
    ],
		date_of_service: '',
		time_of_service: '',
		datetimeError: '',
    years: [],


		service: false,
		serviceName: '',
		price: '',
		coupon: '',
		couponApplied: false,
		bookingError: '',
		vehicle_price_details: '',
		locationAddress: '',
		homeAddress: '',
		email: '',
		phone: '',
		emailError: '',
		phoneError: '',
		name: '',
		selectedLocation: { lat:'', lng:'' },
		selectedAddress: '',
	};

	sendMessage = (params) => {
		axios.post("https://ridenrepair.com/whatsapp/sendpartnerbkconf", params)
		.then((res) => {
			if (res.status === 200) {
			} else {
				console.log("Whatsapp messaging error");
			}
		});
	}

  sendPaymentLink = async (params) => {
    const paymentLink = await axios.get("https://ridenrepair.com/api/get_partner_rzp_pay_link", { params });

    params['payment_link'] = paymentLink.data.short_url;
    params['payment_link_id'] = paymentLink.data.payment_link_id;
    params['final_payment_amount'] = paymentLink.data.final_amount;
    params['invoice_id'] = paymentLink.data.invoice_id;
    
    try {
      axios.post(`https://ridenrepair.com/api/update_partner_booking_invoice`, params);
    } catch (error) {
      console.log('update booking invoice error: ', error);
    }
    try {
      axios.post("https://ridenrepair.com/whatsapp/sendpartnerpaylink", params);
    } catch (error) {
      console.log('sending whatsapp payment link error: ', error);
    }
  }

	handleChangeDateWithEvent = (newDate, event) => {
		if (event.validationError !== null) {
			this.setState({ datetimeError: 'Invalid Date or Time' })
			return;
		} else {
			this.setState({ datetimeError: '' })
			this.setState({ date_of_service: this.dateFormat(newDate, 'yyyy-mm-dd') });
		}
	}

	handleChangeTimeWithEvent = (newTime, event) => {
		if (event.validationError !== null) {
			return;
		}
		this.setState({ time_of_service: this.dateFormat(newTime, 'HH:MM:ss') });
	}

	priceDisplay = () => {
		if (this.state.selected_service === '' || this.state.vehicle_category === '') {
			return (<div></div>)
		} else {
			return (
				<div>
					<h4 style = {{'text-align': 'center'}}>Rs. {this.state.price}/- only</h4>
				</div>
		)}
	}

  updatePrice = (service) => {
    const { vehicle_category } = this.state;
    let price = 0;
    if (service === 'Running Repair At Warehouse' || service === 'Running Repair At Road') {
      price = 300;
    } else if (service === 'General Service') {
      price = (vehicle_category === 'Less than 200cc') ? 200 : 600;
    } else if (service === 'General Service With Oil') {
      price = (vehicle_category === 'Less than 200cc') ? 300 : 800;
    }
    this.setState({ price });
  }

	handleChangeService = (event) => {
    const { value } = event.target;
    this.updatePrice(value);
    this.setState({ selected_service: value });
  }

  addBooking = (params) => {
    try {
      axios.post("https://ridenrepair.com/api/add_b2b_customer_booking", params, { headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        if (res.status === 200) {
          params['booking_id'] = res.data.id;
					this.allocateMechanic(res.data.id);
          this.sendMessage(params);
          this.props.history.push({
            pathname: '/thank-you',
            state: { params }
          });
        }
      });
    } catch (error) {
      this.setState({ bookingError: 'Unable to book, please try again!' })
    }
  }

	getCurrentDateTime = () => {
		const now = new Date();
	
		// Get date in 'yyyy-mm-dd' format
		const date = now.toISOString().split('T')[0];
	
		// Get time in 'HH:MM:ss' format
		const time = now.toTimeString().split(' ')[0];
		
		this.setState({ date_of_service: date, time_of_service: time })
	}

	clickScheduleNow = async (event) => {
		event.preventDefault();

		const tasks = [this.validateEmail, this.getCurrentDateTime];
		for (const fn of tasks) {
			await fn();
		}

		let address_of_service = '';
		let latitude = '';
		let longitude = '';
		let city = '';
		let state = '';
		let zip = '';
		if (this.state.selected_service === 'Running Repair At Road') {
			address_of_service = (this.state.home_address + ', ' + this.state.location_address).replace(/'/g, "\\'");
			if (this.state.location_address === '') {
				this.setState({ bookingError: 'Please fill in the required fields'});
				return;
			}
			latitude = this.state.latitude;
			longitude = this.state.longitude;
			city = this.state.city;
			state = this.state.state;
			zip = this.state.zip;
		} else {
			address_of_service = this.state.selected_warehouse;
			latitude = 28.607686;
			longitude = 77.3872;
			city = 'Noida';
			state = 'Uttar Pradesh';
			zip = '201301';
		}

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		let booking_source = '';

		if (isWebView) {
			if (/iPhone|iPod|iPad/.test(window.navigator.userAgent)) {
				booking_source = 'app_ios';
			} else if (/Android/.test(window.navigator.userAgent)) {
				booking_source = 'app_android';
			} else {
				booking_source = 'app_unknown';
			}
		} else if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator.userAgent)) {
			booking_source = 'mobile_browser';
		} else {
			booking_source = 'desktop_browser';
		}

		const additional_details = this.state.addtnl_details.replace(/'/g, "\\'");

		const total_amount = parseFloat(this.state.price).toFixed(2);
		const service_price = (total_amount / 1.18).toFixed(2);
		const params = {
			email: this.state.email,
			first_name: this.state.name.split(' ')[0],
			last_name: this.state.name.split(' ')[1],
			phone: this.state.partner_phone,
			address: address_of_service,
			latitude: latitude,
			longitude: longitude,
			city: city,
			state: state,
			zip: zip,
			vehicle_name: this.state.vehicle_category + ', '+ this.state.vehicle_name,
			vehicle_year: this.state.vehicle_year,
			service_type: this.state.selected_service,
			date_of_service: this.state.date_of_service,
			time_of_service: this.state.time_of_service,
			coupon_code: this.state.coupon,
			booking_source: booking_source,
			service_price:service_price,
			gst: (total_amount - service_price).toFixed(2),
			total_amount: total_amount,
			odometer_reading: this.state.vehicle_mileage,
			payment_status: 'COD',
			rzp_order_id: '', 
			rzp_payment_id: '', 
			rzp_signature: '',
			additional_details: additional_details,
			paid_amount: 0,
		};

		if ( this.state.vehicle_category === undefined ||  this.state.vehicle_category === '' || this.state.vehicle_name === undefined ||  this.state.vehicle_name === '' 
				|| this.state.selected_service === undefined ||  this.state.selected_service === '' || this.state.date_of_service === undefined ||  this.state.date_of_service === '' 
				|| this.state.time_of_service === undefined ||  this.state.time_of_service === '' || address_of_service === undefined ||  address_of_service === '' 
				|| this.state.email === undefined ||  this.state.email === '' ||  this.state.emailError !== '') {
			this.setState({ bookingError: 'Please fill in the required fields'});
		} else {
			this.setState({ bookingError: ''});
			this.addBooking(params);
		}
	}

	clickScheduleLater = async (event) => {
		event.preventDefault();
		this.setState({ schedule_later: true });
	}

	clickSchedule = async (event) => {
		event.preventDefault();

		const tasks = [this.validateEmail];
		for (const fn of tasks) {
			await fn();
		}

		let address_of_service = '';
		let latitude = '';
		let longitude = '';
		let city = '';
		let state = '';
		let zip = '';
		if (this.state.selected_service === 'Running Repair At Road') {
			address_of_service = (this.state.home_address + ', ' + this.state.location_address).replace(/'/g, "\\'");
			if (this.state.location_address === '') {
				this.setState({ bookingError: 'Please fill in the required fields'});
				return;
			}
			latitude = this.state.latitude;
			longitude = this.state.longitude;
			city = this.state.city;
			state = this.state.state;
			zip = this.state.zip;
		} else {
			address_of_service = this.state.selected_warehouse;
			latitude = 28.607686;
			longitude = 77.3872;
			city = 'Noida';
			state = 'Uttar Pradesh';
			zip = '201301';
		}

		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		let booking_source = '';

		if (isWebView) {
			if (/iPhone|iPod|iPad/.test(window.navigator.userAgent)) {
				booking_source = 'app_ios';
			} else if (/Android/.test(window.navigator.userAgent)) {
				booking_source = 'app_android';
			} else {
				booking_source = 'app_unknown';
			}
		} else if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(window.navigator.userAgent)) {
			booking_source = 'mobile_browser';
		} else {
			booking_source = 'desktop_browser';
		}

		const additional_details = this.state.addtnl_details.replace(/'/g, "\\'");

		const total_amount = parseFloat(this.state.price).toFixed(2);
		const service_price = (total_amount / 1.18).toFixed(2);
		const params = {
			email: this.state.email,
			first_name: this.state.name.split(' ')[0],
			last_name: this.state.name.split(' ')[1],
			phone: this.state.partner_phone,
			address: address_of_service,
			latitude: latitude,
			longitude: longitude,
			city: city,
			state: state,
			zip: zip,
			vehicle_name: this.state.vehicle_category + ', '+ this.state.vehicle_name,
			vehicle_year: this.state.vehicle_year,
			service_type: this.state.selected_service,
			date_of_service: this.state.date_of_service,
			time_of_service: this.state.time_of_service,
			coupon_code: this.state.coupon,
			booking_source: booking_source,
			service_price:service_price,
			gst: (total_amount - service_price).toFixed(2),
			total_amount: total_amount,
			odometer_reading: this.state.vehicle_mileage,
			payment_status: 'COD',
			rzp_order_id: '', 
			rzp_payment_id: '', 
			rzp_signature: '',
			additional_details: additional_details,
			paid_amount: 0,
		};

		if ( this.state.vehicle_category === undefined ||  this.state.vehicle_category === '' || this.state.vehicle_name === undefined ||  this.state.vehicle_name === '' 
				|| this.state.selected_service === undefined ||  this.state.selected_service === '' || this.state.date_of_service === undefined ||  this.state.date_of_service === '' 
				|| this.state.time_of_service === undefined ||  this.state.time_of_service === '' || address_of_service === undefined ||  address_of_service === '' 
				|| this.state.email === undefined ||  this.state.email === '' ||  this.state.emailError !== '') {
			this.setState({ bookingError: 'Please fill in the required fields'});
		} else {
			this.setState({ bookingError: ''});
			this.addBooking(params);
		}
	}

  validateEmail = () => {
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		const isValidEmail = emailRegex.test(this.state.email);
		if (isValidEmail) {
			// email is valid
			this.setState({ emailError: '' });
		} else {
			// email is invalid
			this.setState({ emailError: 'Invalid Email' });
		}
	}

  handleChangeVehicleCategory = (event) => {
    const newVehicleCategory = event.target.value;
  
    this.setState({ vehicle_category: newVehicleCategory }, () => {
      const { selected_service } = this.state;
      this.updatePrice(selected_service);
    });
  };
  

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

  handleLogout = () => {
    localStorage.removeItem('authToken');
    const { history } = this.props;
    history.push('/partner-login');
  };

	setLocationAddress = (locationAddress) => {
		this.setState({ location_address: locationAddress });
	}

	setCity = (city) => {
		this.setState({ city: city });
	}

	setStateValue = (state) => {
		this.setState({ state: state });
	}

	setZip = (zip) => {
		this.setState({ zip: zip });
	}

	setLatitude = (latitude) => {
		this.setState({ latitude: latitude });
	}

	setLongitude = (longitude) => {
		this.setState({ longitude: longitude });
	}

  // chooseWarehouse = (event) => {
  //   this.setState({ selected_warehouse: event.target.value });
  // }

	scrollToBookingForm = () => {
		smoothscroll.polyfill();
		if (this.formSection) {
			this.formSection.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	}

	componentDidMount() {
    this.props.setShowChatBot(false);
		if (typeof window !== 'undefined') {
			import('dateformat').then((dateFormatModule) => {
				this.dateFormat = dateFormatModule.default;
			}).catch((error) => {
				console.log('Unable to load modules');
			})
		}
    const { partner_phone } = this.props.location.state;
    this.setState({ partner_phone });

    const { warehouse } = this.state;

    this.setState({ selected_warehouse: warehouse[0].name });

    axios.get("https://ridenrepair.com/api/years", {mode:"no-cors"})
		.then(response => {
			const years = response.data;
			this.setState({ years });
		})
	}

	allocateMechanic = (booking_id) => {
		try {
			axios.post("https://ridenrepair.com/api/update_b2b_booking_mechanic", { booking_id: booking_id, time_of_service: this.state.time_of_service });
		} catch (error) {
			console.log('update mechanic booking error: ', error);
		}

		try {
			axios.post("https://ridenrepair.com/api/update_b2b_booking_status", { booking_id: booking_id, order_status: 'ASSIGNED' });
		} catch (error) {
			console.log('update mechanic booking error: ', error);
		}
	}

	render() {
		return (
			<>
				<div className="page-content bg-white">
          <header>
            <h2>Hello BigBasket Rider</h2>
            <center>
              <button className="logout-button" onClick={this.handleLogout}>
                Logout
              </button>
            </center>
          </header>

					<div className="booking-container" ref={ref => (this.formSection = ref)}>
						<section className="section-area section-sp1">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 col-md-7 mb-md-50">
										<form className="booking-form">
											<div className="row">
                        <div className="col-lg-3">
													<div className="form-group">
														<h6 className="form-title">VEHICLE YEAR</h6>
														<div className="input-group">
															<select className="form-control" value={this.state.vehicle_year} onChange={this.handleChangevehicleyear}>
																<option value="" selected="selected">Select Year (Optional)</option>
																{this.state.years.map((y) => {
																	return <option value={y.year}>{y.year}</option>
																})}
															</select>
														</div>
													</div>
												</div>
												<div className="col-lg-3">
                          <div className="form-group">
                            <h6 className="form-title">VEHICLE CATEGORY</h6>
                            <div className="input-group">
                              <select name="vehicle_category" className="form-control" value={this.state.vehicle_category} onChange={this.handleChangeVehicleCategory}>
                                <option value="" selected="selected">Choose...</option>
                                {this.state.vehicle_categories.map((y, index) => {
                                  return <option key={index} value={y.name}>{y.name}</option>
                                })}
                              </select>
                            </div>
                          </div>
												</div>

                        <div className="col-lg-3">
                          <div className="form-group">
                            <h6 className="form-title">VEHICLE NAME</h6>
                            <div className="input-group">
                              <input type="text" placeholder="Vehicle Name" className="form-control" name="vehicle_name" value={this.state.vehicle_name} onChange={this.handleChange} />
                            </div>
                          </div>
                        </div>

												<div className="col-lg-3">
													<div className="form-group">
														<h6 className="form-title">VEHICLE MILEAGE</h6>
														<div className="input-group">
															<input type="number" placeholder="Odometer (Optional)" className="form-control" name="vehicle_mileage" value={this.state.vehicle_mileage} onChange={this.handleChange} />
														</div>
													</div>
												</div>

												<div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">SELECT SERVICES NEEDED</h6>
														<div className="row">
															<div className="col-md-12 col-lg-3">
																<div className="form-check custom-radio mb-10">
																	<input type="radio" className="form-check-input" id="customControlAutosizing1" value={'Running Repair At Warehouse'} onChange={this.handleChangeService} checked={this.state.selected_service === 'Running Repair At Warehouse'} />
																	<label className="form-check-label" for="customControlAutosizing1">Running Repair At Warehouse</label>
																</div>
															</div>
															<div className="col-md-12 col-lg-3">
																<div className="form-check custom-radio mb-10">
																	<input type="radio" className="form-check-input" id="customControlAutosizing2" value={'Running Repair At Road'} onChange={this.handleChangeService} checked={this.state.selected_service === 'Running Repair At Road'} />
																	<label className="form-check-label" for="customControlAutosizing2">Running Repair At Road</label>
																</div>
															</div>
															<div className="col-md-12 col-lg-3">
																<div className="form-check custom-radio mb-10">
																	<input type="radio" className="form-check-input" id="customControlAutosizing3" value={'General Service'} onChange={this.handleChangeService} checked={this.state.selected_service === 'General Service'} />
																	<label className="form-check-label" for="customControlAutosizing3">General Service</label>
																</div>
															</div>
															<div className="col-md-12 col-lg-3">
																<div className="form-check custom-radio mb-10">
																	<input type="radio" className="form-check-input" id="customControlAutosizing4" value={'General Service With Oil'} onChange={this.handleChangeService} checked={this.state.selected_service === 'General Service With Oil'} />
																	<label className="form-check-label" for="customControlAutosizing4">General Service With Oil</label>
																</div>
															</div>
														</div>
														<this.priceDisplay />
													</div>
												</div>

                        {this.state.selected_service !== 'Running Repair At Road' &&
                          <div className="col-lg-12">
                            <div className="form-group">
                              <h6 className="form-title">WAREHOUSE</h6>
                              <div className="input-group">
                                <select name="selected_warehouse" className="form-control" value={this.state.selected_warehouse} onChange={this.handleChange}>
                                  <option value="" selected="selected">Choose...</option>
                                  {this.state.warehouse.map((y, index) => {
                                    return <option key={index} value={y.name}>{y.name}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        }
												
												{this.state.selected_service === 'Running Repair At Road' &&
                          <div className="col-lg-12">
                            <div className="form-group">
                              <h6 className="form-title">SELECT SERVICE ADDRESS</h6>
                              <div className="row">
                                <div className="col-xl-12 col-sm-6">
                                  <Places setLocationAddress={this.setLocationAddress} setCity={this.setCity} setStateValue={this.setStateValue} setZip={this.setZip} setLatitude={this.setLatitude} setLongitude={this.setLongitude}/>
                                </div>
                                <div className="col-xl-12 col-sm-6" style={{'padding-top': '30px', 'width': '100%'}}>
                                  <div className="form-group">
                                    <input type="text" name="home_address" className="form-control" placeholder="Complete your address with Flat or Plot No" value={this.state.home_address} onChange={this.handleChange}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }

                        <div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">FILL YOUR INFORMATION</h6>
														<div className="row">
															<div className="col-xl-6 col-sm-6">
																<div className="form-group">
																	<input type="email" name="email" className="form-control" placeholder="Email (for Invoice)" value={this.state.email} onChange={this.handleChange} onInput={this.handleChange} />
																	{this.state.emailError && <p>{this.state.emailError}</p>}
																</div>
															</div>
															<div className="col-xl-6 col-sm-6">
																<div className="form-group">
																	<input type="text" name="name" customEvent="EnterName" className="form-control" placeholder="Name (Optional)" value={this.state.name} onChange={this.handleChange} />
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className="col-lg-12">
													<div className="form-group">
														<h6 className="form-title">ADDITIONAL NOTES</h6>
														<div className="row">
															<div className="col-xl-12 col-sm-6">
																<div className="form-group">
																	<input type="text" name="addtnl_details" className="form-control" placeholder="Please explain what is the issue, if any, with your vehicle in detail" value={this.state.addtnl_details} onChange={this.handleChange}/>
																</div>
															</div>
														</div>
													</div>
												</div>

												{this.state.schedule_later && 
                          <div className="col-lg-12">
                            <div className="form-group">
                              <h6 className="form-title">SELECT DATE and TIME</h6>
                              <p>*Please select a date & time for your service that is at least 30 minutes from the current time.</p>
                            </div>
                          </div>
                        }
                        {this.state.schedule_later && 
                          <div className="col-lg-12">
                            <div className="col-lg-4">
                              <div className="form-group">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <div style={{ 'display': 'inline-flex', 'width': '350px' }}>
                                    <DatePicker
                                      className="custom-calendar"
                                      label="Date"
                                      value={this.state.date_of_service}
                                      onChange={this.handleChangeDateWithEvent}
                                      minDate={dayjs()}
                                    />
                                    &nbsp;&nbsp;&nbsp;
                                    <TimeField
                                      className="custom-calendar"
                                      label="Time"
                                      onChange={this.handleChangeTimeWithEvent}
                                      disabled={this.state.date_of_service === undefined || this.state.date_of_service === ''}
                                      onBlur={this.sendGATimeEvent}
                                    />
                                    {this.state.datetimeError && <p>{this.state.datetimeError}</p>}
                                  </div>
                                </LocalizationProvider>
                              </div>
                            </div>
                          </div>
                        }

                        
                        <div className="col-lg-12">
                          <p/><div style={{ 'font-size': 'x-small' }}><b>*All prices are inclusive of GST. If our mechanic comes to your location and no service/repair is performed, there will be a visit charge of Rs 300/-.</b><p/></div>
                          <p>{this.state.bookingError}</p>
                        {!this.state.schedule_later && 
                          <p>
                            <Link className="btn btn-primary btn-lg" onClick={this.clickScheduleNow}>Schedule Now</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Link className="btn btn-primary btn-lg" onClick={this.clickScheduleLater}>Schedule Later</Link>
                          </p>
                        }
                        {this.state.schedule_later && 
                          <p>
                            <Link className="btn btn-primary btn-lg" onClick={this.clickSchedule}>Schedule</Link>
                          </p>
                        }
                        </div>
                      </div>
										</form>
									</div>
								</div>
							</div>
						</section>
					</div>

				</div>

			</>
		);
	}
}

export default PartnerDashboard;