import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Images (Pre-imported to leverage Webpack's optimizations)
import bg2 from "../../images/background/bg2.png";

class ServicesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checklistExpanded: {}, // Track expansion per service
    };
  }

  toggleChecklist = (serviceId) => {
    this.setState((prevState) => ({
      checklistExpanded: {
        ...prevState.checklistExpanded,
        [serviceId]: !prevState.checklistExpanded[serviceId],
      },
    }));
  };

  renderChecklistItems = (checklist, serviceId) => {
    const isExpanded = this.state.checklistExpanded[serviceId];
    const itemsToDisplay = isExpanded ? checklist.length : Math.min(checklist.length, 9);

    return (
      <>
        {checklist.slice(0, itemsToDisplay).map((item, index) => (
          <li className='checklist-item' key={index}>
            {item}
          </li>
        ))}
        {checklist.length > 9 && (
          <li className='toggle-checklist-item'>
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => this.toggleChecklist(serviceId)}
            >
              {isExpanded ? 'Collapse' : 'View All'}
            </span>
          </li>
        )}
      </>
    );
  };

  render() {
    const { servicesData, vehicleType, model, brand, fuelType, transmission, ccRange, user, setUser } = this.props;

    return (
      <section
        className="section-area bg-gray service-wraper1 section-sp2"
        style={{
          backgroundImage: `url(${bg2})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
        }}
      >
        <div className="container">
          <div className="row spno">
            {servicesData && servicesData.map(service => (
              <div key={service.id} className="col-xl-12 col-lg-12" style={{ padding: '2%' }}>
                <div className="feature-container feature-bx1 mb-sm-30">
                  <div className="icon-lg mb-10 text-primary">
                    {/* Use a mapping for service icons to avoid dynamic requires */}
                    <img
                      className='services-icon'
											src={require(`../../images/vehicle_images/${service['image-icon']}`)}
                      alt={`Icon for ${service.label}`}
                      loading="lazy"
                    />
                  </div>
                  <div className="icon-content services-content">
                    <h3 className="ttr-tilte">{service.label}</h3>
                    <div className="perks-time-price-container">
                      <div className="perks">
                        <ul>
                          {service.perks.map((perk, index) => (
                            <li key={index}><span className="check-icon">&bull;</span> {perk}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="time-and-price">
                        <div className="time-taken">
                          <FontAwesomeIcon icon={faClock} />
                          <span>{service['time-taken']}</span>
                        </div>
                      </div>
                    </div>
                    <div className="checklist">
                      <ul className="checklist-items">
                        {this.renderChecklistItems(service.checklist, service.id)}
                      </ul>
                    </div>
                    <div className='price-buttons'>
                      <div className="price-info">
                        {/* {vehicleType === 'car' ? (
                          <span className="actual-price">&#8377;{service['strikethrough-price'] || service['price']}</span>
                        ) : (
                          <>
                            {service['strikethrough-price'] && <span className="strike-through">&#8377;{service['strikethrough-price']}</span>}
                            <span className="actual-price">&#8377;{service['price']}</span>
                          </>
                        )} */}
                        {service['strikethrough-price'] && <span className="strike-through">&#8377;{service['strikethrough-price']}</span>}
                        <span className="actual-price">&#8377;{service['price']}</span>
                      </div>
                      <div className='add-to-cart-btns'>
                        <Link to={{
                          pathname: "/checkout",
                          state: {
                            vehicle_category: vehicleType,
                            brand: brand.label,
                            fuel_type: fuelType,
                            transmission: transmission,
                            cc_range: ccRange,
                            vehicle_name: model.label,
                            service_type: service.label,
                            price: service['price']
                            // price: vehicleType === 'car' ? service['strikethrough-price'] || service['price'] : service['price']  // Use strikethrough-price for cars if available
                          }
                        }}>
                          <button className="add-to-cart-btn">Checkout</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default ServicesSection;
