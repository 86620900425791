import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import bg2 from "../../images/background/bg1.png";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class TermsAndConditions extends Component{

	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}
	
	render(){

		const { isClient } = this.state;

		return (
			<>
				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={ isClient ? { backgroundImage: "url("+bnrImg+")" } : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Terms and Conditions</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Terms and Conditions</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white" style={ isClient ? { backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat" } : {} }>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6 mb-30 mb-md-50">
									<div className="heading-bx">
										<h4 className="title-ext text-primary">Terms and Conditions</h4>
										<p>
                        The Terms of Use laid down herein (referred to as “Terms”) as uploaded on <a href="https://ridenrepair.com/">https://ridenrepair.com/</a> shall govern the usage, rights and liabilities of RIDE N REPAIR PRIVATE LIMITED (hereinafter referred to in the present document as “Company”/ “RIDE N REPAIR”/ "We"/ "Us" or "Our") and the User (“You / User”) while accessing and using the RIDE N REPAIR website and mobile application as defined below.<br/><br/>
                        RIDE N REPAIR is the brand / mark of RIDE N REPAIR PRIVATE LIMITED. Through its platform <a href="https://ridenrepair.com/">https://ridenrepair.com/</a> the Company is engaged in the business of doorstep maintenance and repair services including but not limited to servicing, cleaning, check-up, dry wash, etc. of two wheeler vehicles.<br/><br/>
                        These Terms shall govern the legal rights and responsibilities with respect to the present and future User’s access to the Platform.<br/><br/>
                        By accessing the Platform and continuing its use thereafter, the User declares that it has read, understood and unconditionally agreed to the contents of these Terms which shall form a binding contract of the User with RIDE N REPAIR.
										</p>
                    <p>
                        <strong>I. DEFINITIONS:</strong>
                        <ul>
                            <li class="terms-element-list">“Platform” shall mean the mobile application and the website of the Company.</li>
                            <li class="terms-element-list">“Technician” shall mean and include the independent third party Technician engaged with the Company to provide services to the User.</li>
                            <li class="terms-element-list">“User” shall mean and include the user / participant / player / customer of the Platform and include an individual who:
                                <ul>
																	<ol type="a">
                                    <li class="terms-subelement-list">Is legally above 18 years of age.</li>
                                    <li class="terms-subelement-list">A person of sound mind capable of entering into a contract.</li>
                                    <li class="terms-subelement-list">Has accessed or has registered on the Platform in accordance with the procedure outlined as per this policy.</li>
																	</ol>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    <p>
                        <strong>II. USAGE:</strong>
                        <ul>
                            <li class="terms-element-list">A. Any User accessing the Platform for using or otherwise consuming the Service/indulging in surfing or exploring of the Platform shall be bound by these Terms, and all other rules, regulations and policies referred to herein or provided by the Company in relation to any of the Services.</li>
                            <li class="terms-element-list">B. The Company holds the right to modify the Terms of the present document by posting the same on the Platform without any prior express intimation to the Users. Users are hereby advised to review the Terms periodically and/or every time before using the Mobile Application, as per their convenience. The updated Terms shall supersede the previous versions of the Terms and shall take effect immediately upon the Uploading of the same on the Platform. The continuous use and access to the Platform shall be deemed as an acceptance of the prevalent Terms by the User.</li>
                            <li class="terms-element-list">C. In the event that the User does not agree with the Terms laid down by the Company, they may choose not to access the Platform or refrain from continuing to use the Platform as the case may be.</li>
                            <li class="terms-element-list">D. The Company may, subject to the laws laid down and prevalent in India and on the grounds laid down in the present Terms, restrict, suspend or terminate the use of the Platform by any User Account to all or any part of the Platform of any or all of the Company Services. The Grounds for restrictions, suspension or termination shall be as follows:
                                <ul>
																	<ol type="a">
                                    <li class="terms-subelement-list">a) Breach of the Terms;</li>
                                    <li class="terms-subelement-list">b) Access or use of the Application by any User below the age of 18;</li>
                                    <li class="terms-subelement-list">c) Where the Company has reasons to believe that the User has conducted any illegitimate transaction against which the Platform may suffer loss / fine / charge;</li>
                                    <li class="terms-subelement-list">d) Where the Company has reasons to believe that the User is location spoofing by disguising their location;</li>
                                    <li class="terms-subelement-list">e) Falsifying their personal information (including, but not limited to, name, email address, bank account details and/or any other information or documentation;</li>
																		<li class="terms-subelement-list">f) Where the User has uploaded text or media which is inappropriate, vulgar, objectionable, defamatory, violent, etc. at the sole discretion of the Company;</li>
                                    <li class="terms-subelement-list">g) Any other misuse of the Platform;</li>
                                    <li class="terms-subelement-list">h) Any violation of the prevalent laws in force in India;</li>
																	</ol>
                                </ul>
                            </li>
                            <li class="terms-element-list">E. The Company shall also hold the right to initiate appropriate civil /criminal action and demand compensation for loss suffered by the Company due to any act as aforementioned in the grounds (a) to (h).</li>
                            <li class="terms-element-list">F. The Company shall subject to the laws laid down and prevalent in India, at its sole and absolute discretion, change, suspend or discontinue all or any part of the Platform / Services Establish general practices and limits concerning the use of the Platform.</li>
                            <li class="terms-element-list">G. The User expresses consent to receiving communications such as announcements, administrative messages, and advertisements from the Company or any of its partners, licensors or associates/affiliates over the contact information provided by the User including E-mail and Whatsapp.</li>
                            <li class="terms-element-list">H. Use of the Platform is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit Company’s right to comply with governmental, court, and law-enforcement requests or requirements relating to User’s use of the Platform.</li>
                        </ul>
                    </p>
										<p>
                        <strong>III. INTELLECTUAL PROPERTY:</strong>
                        <ul>
                            <li class="terms-element-list">A. The Platform i.e. RIDE N REPAIR includes without limitation a combination of content created by the Company its partners, affiliates, licensors, associates and / or submitted by other Users. The Intellectual Property Rights in all software underlying the Platform and material published on the Platforms, including but not limited to all content(s), software, advertisements, written content, photographs, images, illustrations, marks, logos, audio or video clipping and Flash animations (hereinafter referred to as “Intellectual Property”) shall be owned by the Company. User shall be solely responsible for all content that a User uploads on to the Platform whether in whole or in part. Each User represents and warrants that he/she owns all intellectual property rights in the User Content submitted on the Platform and that no part of such content infringes upon any intellectual property of a third party.</li>
                            <li class="terms-element-list">B. User further confirms and undertakes to not display or use the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on the Platforms.</li>
                            <li class="terms-element-list">C. User agrees to indemnify and hold harmless the Company, its directors, employees, affiliates and assigns against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including but not limited to infringement of intellectual property rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform, by such User or through the User's actions or omissions.</li>
                            <li class="terms-element-list">D. User hereby grants to the Company and its affiliates, directors, licensors and associates a world-wide, royalty-free, non-exclusive, sub-licensable, irrevocable right to use, reproduce, create derivative works of, distribute, publicly perform, transfer and/or publish Users' Content as the Company may deem fit for the following purposes:
                                <ul>
																	<ol type="a">
                                    <li class="terms-subelement-list">a. Displaying User Content on the Platform excluding the personal identifiable information such as address, contact details, etc.</li>
                                    <li class="terms-subelement-list">b. Storing User Content in a database accessible by other Users for a Charge.</li>
                                    <li class="terms-subelement-list">c. Distributing Users’ Content to other Users seeking to download. This right shall apply to the distribution and the storage of Users’ Content in any form, medium or technology.</li>
																	</ol>
                                </ul>
                            </li>
                        </ul>
                    </p>
										<p>
                        <strong>IV. THIRD PARTY SITES, SERVICES AND PRODUCTS:</strong>
                        <ul>
                            <li class="terms-element-list">A. The Platform may contain links to other internet sites owned and operated by third parties.</li>
                            <li class="terms-element-list">B. Use of each of those external links or third-party sites is subject to the conditions, if any, posted by such sites. It is expressly and unequivocally stated that the Company does not exercise any control over any internet third-party sites apart from its own Platform/s and cannot in any manner whatsoever be held responsible for any content on any third-party internet site.</li>
                            <li class="terms-element-list">C. By entering any third-party website or clicking on any third-party link, the User admits to do so at his own risk and cost and shall not under any circumstances hold the Company liable for any consequence of the User’s actions/visit to such third party sites. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate third-party.</li>
                            <li class="terms-element-list">D. Platform’s inclusion of third-party content or links to third-party internet sites is not an endorsement by the Company of such third-party.</li>
                            <li class="terms-element-list">E. Users' correspondence, transactions, and usage of the services/offers of such third-party sites shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third-party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third-party.</li>
                            <li class="terms-element-list">F. Platform contains content that is created by the Company as well as content provided by third parties. Company does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not be relied upon by the User in utilizing the Services provided on the Platform including while participating in any of the Contest(s) hosted on the Platform.</li>
                        </ul>
                    </p>
										<p>
                        <strong>V. PRIVACY POLICY:</strong>
                        <ul>
                            <li class="terms-element-list">All information collected from User(s) is subject to Company’s Privacy Policy which is available on <a href="https://www.ridenrepair.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.ridenrepair.com/privacy-policy</a>.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>VI. USER’S CONDUCT:</strong>
                        <ul>
                            <li class="terms-element-list">A. User agrees to provide true, accurate, current, and complete information at the time of registration and at all other times (as required by the Company). User further agrees to update and keep updates their registration information, wherever the same is editable.</li>
                            <li class="terms-element-list">B. User shall not register or operate more than one user account with the Platform.</li>
                            <li class="terms-element-list">C. User agrees to receive any communication from the Company by marking e-mails or sending SMS from the Company. Company shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User’s junk or spam folder or in the case of error on the email or SMS sending platform(s).</li>
                            <li class="terms-element-list">D. User is responsible for maintaining the confidentiality of their accounts and passwords. User agrees to immediately notify the Company of any unauthorized use of their passwords.</li>
                            <li class="terms-element-list">E. User agrees to exit/log-out of their accounts on the Platform at the end of each session and to keep their login and user information private and confidential. Company shall not be responsible for any loss or damage that may result if the User failed to comply with these requirements.</li>
                            <li class="terms-element-list">F. User agrees not to copy, modify, rent, lease, loan, sell, assign, distribute reverse engineer, grant or otherwise transfer any right to the technology or software underlying the Platforms or Company services.</li>
                            <li class="terms-element-list">G. User agrees that they shall not modify or cause to be modified any files or software that is part of the Company services.</li>
                            <li class="terms-element-list">H. User agrees not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus or any other attempt to disrupt Company services or any other person’s enjoyment of User Services.</li>
                            <li class="terms-element-list">I. No User shall attempt to gain unauthorized access to any of the User accounts, servers or networks connected to the services provided by the Company by any means other than the means provided by the Platform including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of Services.</li>
                            <li class="terms-element-list">J. Without limiting the foregoing, User agrees not to use the Platform for any of the following:
                                <ul>
                                	<ol type="a">
                                    <li class="terms-subelement-list">a. To engage in any obscene, offensive, indecent, racial, sexist, communal, anti-national, objectionable, defamatory, or abusive action or communication.</li>
                                    <li class="terms-subelement-list">b. To harass, stalk, threaten, or otherwise violate any legal rights of other individuals / users of the Platform either on the Platform or otherwise.</li>
                                    <li class="terms-subelement-list">c. To Transmit any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful content.</li>
                                    <li class="terms-subelement-list">d. To transmit files that contain viruses, corrupt data, malware, or any other similar software or program that may damage or adversely affect the operation of another person’s computer, Company Platform, etc.</li>
                                    <li class="terms-subelement-list">e. To advertise, offer or sell any goods or services for any commercial purpose on the Platform without explicit written consent of the Company.</li>
                                    <li class="terms-subelement-list">f. To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material.</li>
                                    <li class="terms-subelement-list">g. To restrict or inhibit any other User / Users from using and enjoying the Platform.</li>
                                    <li class="terms-subelement-list">h. To collect or store personal information about other Users.</li>
                                    <li class="terms-subelement-list">i. To impersonate any person or entity.</li>
                                    <li class="terms-subelement-list">j. To forge headers or other Company data, documents, etc.</li>
																	</ol>
                                </ul>
                            </li>
                            <li class="terms-element-list">K. If a username, or display name on social media login that is used to login to the Platform, in Company’s considered opinion is obscene, indecent, abusive or that might subject Company to public disparagement or scorn, is already in use by another User of the Platform, as the case may be, Company reserves the right, without prior notice to the User, to restrict usage of such names, which in Company’s opinion fall within any of the said categories and/or change such Company’s username/display name or delete such username/display name and posts from the Platform, deny such User access to the Platform, or any combination of these options.</li>
                            <li class="terms-element-list">L. Unauthorized access to the Platform is a breach of these Terms, and a violation of the law. User agrees not to access the Platform by any means other than through the interface that is provided by the Company for use in accessing the Platforms. User agrees not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing.</li>
                        </ul>
                    </p>
										<p>
                        <strong>VII. USER INFORMATION:</strong>
                        <ul>
                            <li class="terms-element-list">A. The User shall be required to register on the Platform by creating an account and adding information such as:
                                <ol type="i">
                                    <li class="terms-subelement-list">Name</li>
                                    <li class="terms-subelement-list">Username</li>
                                    <li class="terms-subelement-list">E-mail ID</li>
                                    <li class="terms-subelement-list">Phone Number</li>
                                    <li class="terms-subelement-list">Password</li>
                                    <li class="terms-subelement-list">Location (optional)</li>
                                </ol>
                            </li>
                            <li class="terms-element-list">B. The Platform may also ask for your personal opinion in relation to Services, access to contacts already saved in your device, etc., which shall be given at the sole discretion of the User. However, failing to provide the aforesaid information shall prevent the User access to certain features of the Platform.</li>
                            <li class="terms-element-list">C. The Platform retains the right to confirm the User details such as Phone Number by sending an OTP (One Time Password) verification failing which the User shall not be able to access the Platform.</li>
                            <li class="terms-element-list">D. The User represents that all information provided to Platform in such process is true, accurate and correct and in the event of any change in the said information, the same shall be updated on the Platform within a reasonable period of time by the User.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>VIII. BOOKINGS:</strong>
                        <ul>
                            <li class="terms-element-list">A. The User may make an Order for services through the Platform and provide the information as requested by the Platform. The Platform shall upon receiving an order, make reasonable efforts to find a technician who is able to provide the services at the requested time. In the event the Platform is unable to find a Service Professional for a specific timeslot, the User shall be requested to find an alternative time.</li>
                            <li class="terms-element-list">B. Once the technician has accepted the Order and the Order is confirmed, User shall receive confirmation against the same and the User will be required to make the payment in accordance with these Terms at or before the time of receiving services.</li>
                            <li class="terms-element-list">C. Any Order which is cancelled before the technician has confirmed the same shall not be charged. Any other cancelled order shall be governed by the cancellation policy of the Company.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>IX. SERVICES:</strong>
                        <ul>
                            <li class="terms-element-list">A. The Platform shall provide the following Services to the Users:
                                <ol type="a">
                                    <li class="terms-subelement-list">The Platform allows the User(s) to register and login Pan India and obtain two wheeler repair and maintenance services from technicians in the locations as selected by the User.</li>
                                    <li class="terms-subelement-list">The Platform allows the Users to get connected with motor vehicle technicians and obtain varied form of services at a cost mutually agreed upon by the User and the Platform.</li>
                                    <li class="terms-subelement-list">The Platform allows the Users to make easy payments for the Services received by the User from any technician through the Platform.</li>
                                    <li class="terms-subelement-list">The Platform stores the data in relation to the past maintenance history, model, type, age, etc., of the vehicle in order to provide a better, well-informed service in relation to the vehicle.</li>
                                    <li class="terms-subelement-list">The Platform is developed for personal and non-commercial use of the Users unless expressly agreed upon by the User and the Company. The Platform is to be used within the territory of India and not beyond.</li>
                                </ol>
                            </li>
                            <li class="terms-element-list">B. LIMITATION: While using the Platform to conduct transactions with any technicians, Users are responsible for making their own determinations that the technician’s capability and competence are suitable for their requirements. The Platform shall not be responsible for ensuring the standard of quality offered by any technician. Further, Platform can’t or won’t be responsible for ensuring the accuracy of the Services available through its Platform. The Platform further does not hold any responsibility for the opinions, conduct, actions of the technicians booked through its platform.</li>
                            <li class="terms-element-list">C. As a general rule, all orders placed on the RIDE N REPAIR App and subsequent Services are treated as confirmed. However, upon a Customer’s successful completion of placing an Order, we may call the Customer on the mobile number provided by them to confirm certain details of the placed Order, such as the delivery location, price, details of vehicle, and informing the customer about the estimated arrival time of technicians for each Order. For this purpose, Customers will be required to share certain information with us, including but not limited to a Customer’s (i) first and last name (ii) mobile number (iii) email address and (iv) location / service address. It shall be the Customer’s sole responsibility to bring any incorrect details to our attention.</li>
                            <li class="terms-element-list">D. In addition to the foregoing, we may also contact you by phone and / or email to inform and/or confirm any change in the Order, due to availability or unavailability of certain technicians in the area. We may also contact you by phone and / or email to inform you of any change in the Order or change in price of any item in the Order as informed to us by the technician. Please note that any change or confirmation of the Order shall be treated as final. It is clarified that RIDE N REPAIR reserves the right to not process the User's Order in the event User is unavailable on the phone or any other means of communication at the time when we call you for confirming the Order.</li>
                        </ul>
                    </p>
										<p>
                        <strong>X. PAYMENTS:</strong>
                        <ul>
                            <li class="terms-element-list">A. The Payments made on or through the Platform shall compulsorily be in Indian Rupees (INR).</li>
                            <li class="terms-element-list">B. The Payments on the RIDE N REPAIR Platform or through the Platform can be made through:
                                <ol type="i">
                                    <li class="terms-subelement-list">Debit Card</li>
                                    <li class="terms-subelement-list">Credit Card</li>
                                    <li class="terms-subelement-list">UPI</li>
                                    <li class="terms-subelement-list">Cash on delivery</li>
                                </ol>
                            </li>
                            <li class="terms-element-list">C. The User agrees that RIDE N REPAIR is authorized to collect, on behalf of the technician, the charges for the products and services provided by the technician, as the case may be.</li>
                            <li class="terms-element-list">D. The User hereby acknowledges and agrees that the Platform is not a banking or financial service but is merely a facilitator providing an automated online electronic payment system. Further, RIDE N REPAIR is neither acting as a trustee nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</li>
                            <li class="terms-element-list">E. RIDE N REPAIR acts as the payment agent for the technician strictly for the purpose of accepting payments from Users on behalf of the technician, as the case may be. The User while using the Platform shall not under any circumstances make payment to the technician directly unless an option regarding the same is being reflected in the Platform itself.</li>
                            <li class="terms-element-list">F. RIDE N REPAIR retains the right to charge a platform fee at its discretion. The Platform Fee shall vary on a case-to-case basis and shall be subject to the final consent of the User.</li>
                            <li class="terms-element-list">G. All charges and fees are inclusive of applicable taxes.</li>
                            <li class="terms-element-list">H. The Platform may, at its sole discretion, offer promotional codes, discounts, offers that may be redeemed for credits, other features, or benefits related to the Services.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>XI. PUBLICITY:</strong>
                        <ul>
                            <li class="terms-element-list">A. Publishing views, reviews, opinions on the Platform by any User constitutes permission to the Platform, to use the User’s content, name, likeness, reviews, views, and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and, or additional compensation whatsoever.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>XII. GENERAL CONDITIONS:</strong>
                        <ul>
                            <li class="terms-element-list">A. If it comes to the notice of the Company that any governmental, statutory, or regulatory compliances or approvals are required for continuing any feature of the Platform or if it comes to the notice of Company that carrying on of any such feature(s) is prohibited, then the Company shall withdraw such feature(s) without prior notice to any User. User agrees not to make any claim in respect of such cancellation or withdrawal of the feature(s) in any manner.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>XIII. GRIEVANCE REDRESSAL:</strong>
                        <ul>
                            <li class="terms-element-list">A. The Company has adopted a strict and disciplined Grievance Redressal mechanism with a goal to provide immediate resolution of any genuine grievance of the Users.</li>
                            <li class="terms-element-list">B. The Company encourages the User to approach the Company in the event of any complaint / grievance of the User concerned through its grievance support system.</li>
                            <li class="terms-element-list">C. Where the User fails to apprise the Company of its grievance before approaching any authority, the Company shall not be liable for compensating for any loss occurred to the User and not being in the knowledge of the Company.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>XIV. DISPUTE AND DISPUTE RESOLUTION:</strong>
                        <ul>
                            <li class="terms-element-list">A. In the event of any dispute between the Company and the Users, the parties to the dispute shall to their best endeavors attempt to resolve the issues amicably.</li>
                            <li class="terms-element-list">B. In case of any grievance of the User, the user hereby consents to approach the Company through its grievance redressal mechanism as detailed in the present document and raise its grievance before the Company prior to approaching any other authority. The Grievance shall necessarily include the User identity, grievance, reason and total loss (if any) suffered by the User. Under no circumstances shall Company be liable to compensate the User for any amount greater than the last 2 amounts deposited in the Platform by the User.</li>
                            <li class="terms-element-list">C. The User agrees to keep the details of the Complaint / grievance confidential.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>XV. RELEASE AND LIMITATIONS OF LIABILITY:</strong>
                        <ul>
                            <li class="terms-element-list">A. User shall access the Services provided on the Platform voluntarily and at their own risk. Company shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by User or any other person or entity during the course of access to the Services.</li>
                            <li class="terms-element-list">B. By accessing the Company Services provided therein, User hereby releases from and agrees to indemnify Company, and/or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access to the Company Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise.</li>
                            <li class="terms-element-list">C. Company accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes.</li>
                            <li class="terms-element-list">D. User shall be solely responsible for any consequences which may arise directly or indirectly due to their access of Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to Services, including provision of incorrect address or other personal details. User also undertakes to indemnify Company and their respective officers, directors, employees, and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by Company on account of such act on the part of the Users.</li>
                            <li class="terms-element-list">E. In consideration of Company allowing the User to access the Services, to the maximum extent permitted by law, the User waives and releases each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against the Company, its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of Services.</li>
                        </ul>
                    </p>
										<p>
                        <strong>XVI. DISCLAIMERS:</strong>
                        <ul>
                            <li class="terms-element-list">A. To the extent permitted under law, Company shall not be responsible for any harm resulting from downloading or accessing any information or material. Company Services Company disclaims any responsibility for, and if a User pays for access to one of Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by Company’s maintenance on the servers or the technology that underlies our sites, failures of Company’s service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, Company does not provide any warranty as to the content on the Platform(s). Platform(s) content is distributed on an "as is, as available" basis.</li>
                            <li class="terms-element-list">B. Any material accessed, downloaded, or otherwise obtained through the Platform is done at the User's discretion, competence, acceptance and risk, and the User will be solely responsible for any potential damage to User's computer system or loss of data that results from User's download of any such material.</li>
                            <li class="terms-element-list">C. Company shall make best endeavour to ensure that the Platform(s) is error-free and secure, however, neither Company nor any of its partners, licensors or associates makes any warranty that:
                                <ol type="a">
                                    <li class="terms-subelement-list">The Platform(s) will meet User/ Users' requirements.</li>
                                    <li class="terms-subelement-list">Platform(s) will be uninterrupted, timely, secure, or error free.</li>
                                    <li class="terms-subelement-list">The results that may be obtained from the use of Platform(s) will be accurate or reliable; and</li>
                                    <li class="terms-subelement-list">The quality of any Product(s), Services, information, or other material that User purchase or obtain through Platform(s) will meet Users' expectations.</li>
                                </ol>
                            </li>
                            <li class="terms-element-list">D. To the extent permitted under law, neither Company nor its partners, licensors or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use our sites, even if we have been advised of the possibility of such damages.</li>
                            <li class="terms-element-list">E. To the extent permitted under law, in the event of suspension or closure of any Service(s), Event(s), Offer(s) or Contest(s), Users shall not be entitled to make any demands, claims, on any nature whatsoever.</li>
                            <li class="terms-element-list">F. User’s use of the Platform, or any content, available thereof is entirely at their own option and risk. The Company shall be held harmless and not be liable for any direct, indirect, incidental, consequential, special, exemplary, punitive, monetary or any other damages, fees, fines, penalties, or liabilities whatsoever arising out of or relating to the use of the Platform or any content thereof. To the maximum extent permitted by applicable law, the total liability of Company to the User for any damages (regardless of the foundation for the action) shall not exceed in the aggregate the total winnings in the concerned User’s account.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>XVII. MISCELLANEOUS:</strong>
                        <ul>
                            <li class="terms-element-list">A. The decision of Company with respect to the awarding of discounts, rewards, etc. shall be final, binding, absolute and non-contestable.</li>
                            <li class="terms-element-list">B. To the extent permitted by law, Company makes no representations or warranties as to the quality, suitability or merchantability of any prizes and shall not be liable in respect of the same.</li>
                            <li class="terms-element-list">C. Company may be required under certain legislations, to notify User of certain events. User hereby acknowledges and consents that such notices will be effective upon the Company posting them on the Platforms or delivering them to the User through Notifications on the App and/ or the email address (if available) provided by the User at the time of registration. If they do not provide the Company with accurate information, the Company cannot be held liable for failure to notify the User.</li>
                            <li class="terms-element-list">D. Company shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Company’s control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials or any cancellation Event(s).</li>
                            <li class="terms-element-list">E. Company’s failure to exercise or enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision.</li>
                            <li class="terms-element-list">F. User agrees that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Company’s or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.</li>
                            <li class="terms-element-list">G. These Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference, constitute the entire agreement between the User/ User and the Company and govern your use of the Platform, superseding any prior agreements that any User/ User may have with the Company.</li>
                            <li class="terms-element-list">H. If any part of these Terms and Conditions is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms and Conditions shall continue in full force.</li>
                            <li class="terms-element-list">I. Company reserves the right to moderate, restrict or ban the use of the Platform, specifically to any User/ User, or generally, in accordance with Company’s policy/policies from time to time, at its sole and absolute discretion and without any notice.</li>
                            <li class="terms-element-list">J. Company may, at its sole and absolute discretion, permanently close or temporarily suspend any Company Services.</li>
                            <li class="terms-element-list">K. Company may from time-to-time conduct/organize, promotions/offers on the platform. Any two or more promotions cannot be clubbed together with any other promotions that are running simultaneously on the Platforms, unless specified.</li>
                        </ul>
                    </p>
									</div>
								</div>
							</div>
						</div>
					</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default TermsAndConditions;