import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Images
import logo from "../../images/logo.png";

class MechanicLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      password: '',
      loginSuccess: false,
      loginError: false,
      errorMessage: '',
      isClient: false
    };
  }

  componentDidMount() {
    this.props.setShowChatBot(false);
    this.setState({ isClient: true });
    const token = localStorage.getItem('authToken');
    if (token) {
      axios.get('https://ridenrepair.com/api/get_user_data', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) 
      .then((response) => {
        const userData = response.data.user_data;

        this.props.setUser({ is_logged: true, fn: userData.mechanic_name, ph: userData.mechanic_phone });
        this.props.history.push('/mechanic-dashboard', {
          mechanicName: userData.mechanic_name,
          mechanicPhone: userData.mechanic_phone,
        });
      })
      .catch((error) => {
        console.error('Auto-login error:', error);
      });
    }
  }

  handlePhoneChange = (event) => {
    this.setState({ phone: event.target.value });
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { phone, password } = this.state;

    axios.post('https://ridenrepair.com/api/mechanic_login', { phone, password })
      .then(response => {
        // Handle successful login
        this.setState({ loginSuccess: true });
        
        localStorage.setItem('authToken', response.data.jwt_token);
        localStorage.setItem('mechanic_phone', response.data.mechanic_phone);
        
        this.props.setUser({ is_logged: true, fn: response.data.mechanic_name, ph: response.data.mechanic_phone });
        this.props.history.push('/mechanic-dashboard', {
          mechanicName: response.data.mechanic_name,
          mechanicPhone: response.data.mechanic_phone,
        });
      })
      .catch(error => {
        // Handle login error
        console.error('Login error:', error.response);
        this.setState({
          loginError: true,
          errorMessage: error.response ? error.response.data.message : 'An error occurred during login.',
        });
      });
  }

  render() {
    const { isClient } = this.state;
    return (
      <>
        <div className="page-wraper account-wraper bg-gray py-100 px-20">
          <div className="my-account">
            <div className="logo">
              {/* <Link to="/"> */}
              { isClient && <img src={logo} alt="" /> }
              {/* </Link> */}
            </div>
            {this.state.loginSuccess && <div className="alert alert-success">Successfully logged in</div>}
            {this.state.loginError && <div className="alert alert-danger">{this.state.errorMessage}</div>}
            <form>
              <div className="form-group">
                <center><p>Mechanic Login</p></center>
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Phone" value={this.state.phone} onChange={this.handlePhoneChange} />
              </div>
              <div className="form-group">
                <input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} />
              </div>
              <div className="form-group">
                <button className="btn mb-10 btn-lg btn-primary w-100" onClick={this.handleSubmit}>Login</button>
              </div>
            </form>
            {/* <div className="widget widget-link-2"> */}
              <ul style={{ textAlign: 'center' }}>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              </ul>
            {/* </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(MechanicLogin);
