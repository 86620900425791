import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import TestimonialSection from "../elements/testimonial";

import { Helmet } from 'react-helmet';

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";
import AvailableCitiesModal from '../elements/available-cities-modal';

class AboutUs extends Component {
  render() {
    const canonicalUrl = 'https://www.ridenrepair.com/about';
    const { isMobileBrowser, isMobileApp } = this.props;

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>About - Ride N Repair</title>
          <meta
            name="description"
            content="Discover the story behind Ride N Repair, your trusted partner for all two-wheeler repair needs. Learn about our mission, values, and commitment to excellence."
          />
          <meta
            name="keywords"
            content="About Ride N Repair, Mission, Values, Two-wheeler Repair, Doorstep Bike Service, Expert Mechanics"
          />
        </Helmet>

        {this.props.citiesModal && (
          <AvailableCitiesModal
            closeModal={() => this.props.setCitiesModal(false)}
          />
        )}

        <Header
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
          user={this.props.user}
          setUser={this.props.setUser}
        />

        <div className="page-content bg-white">
          <div
            className="page-banner ovbl-dark"
            style={{ backgroundImage: `url(${bnrImg})` }}
          >
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1>
                  <span>About Us</span>
                </h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="las la-home"></i>Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      About Us
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <AboutUsTwoSection
            setCitiesModal={this.props.setCitiesModal}
            isMobileBrowser={isMobileBrowser}
            isMobileApp={isMobileApp}
          />

          <ServicesSection
            user={this.props.user}
            setUser={this.props.setUser}
            isMobileBrowser={isMobileBrowser}
            isMobileApp={isMobileApp}
          />

          <TestimonialSection
            setCitiesModal={this.props.setCitiesModal}
            isMobileBrowser={isMobileBrowser}
            isMobileApp={isMobileApp}
          />
        </div>

        <Footer
          setCitiesModal={this.props.setCitiesModal}
          isMobileBrowser={isMobileBrowser}
          isMobileApp={isMobileApp}
        />
      </>
    );
  }
}

export default AboutUs;
