export const cities = [
  {
    key: 'bengaluru',
    name: 'Bengaluru'
  },
  {
    key: 'gurugram',
    name: 'Gurugram'
  },
  {
    key: 'delhi',
    name: 'Delhi'
  },
  {
    key: 'greaternoida',
    name: 'Greater Noida'
  },
  {
    key: 'ghaziabad',
    name: 'Ghaziabad'
  },
  {
    key: 'faridabad',
    name: 'Faridabad'
  },
  {
    key: 'hyderabad',
    name: 'Hyderabad'
  },
  {
    key: 'noida',
    name: 'Noida'
  },
  {
    key: 'mumbai',
    name: 'Mumbai'
  },
  {
    key: 'navimumbai',
    name: 'Navi Mumbai'
  },
  {
    key: 'kalyan',
    name: 'Kalyan'
  },
  {
    key: 'lucknow',
    name: 'Lucknow'
  },
  {
    key: 'thane',
    name: 'Thane'
  },
  {
    key: 'pune',
    name: 'Pune'
  },
  {
    key: 'indore',
    name: 'Indore'
  },
  {
    key: 'surat',
    name: 'Surat'
  },
  {
    key: 'guwahati',
    name: 'Guwahati'
  },
  {
    key: 'bhubaneswar',
    name: 'Bhubaneswar'
  },
  {
    key: 'mysore',
    name: 'Mysore'
  },
  {
    key: 'kolkata',
    name: 'Kolkata'
  },
  {
    key: 'nashik',
    name: 'Nashik'
  },
  {
    key: 'ahmedabad',
    name: 'Ahmedabad'
  },
  {
    key: 'bhopal',
    name: 'Bhopal'
  },
  {
    key: 'nagpur',
    name: 'Nagpur'
  },
  {
    key: 'chandigarh',
    name: 'Chandigarh'
  },
  {
    key: 'jaipur',
    name: 'Jaipur'
  },
  {
    key: 'dehradun',
    name: 'Dehradun'
  },
  {
    key: 'visakhapatnam',
    name: 'Visakhapatnam'
  },
  {
    key: 'chennai',
    name: 'Chennai'
  }
];
