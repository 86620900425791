import React, { useMemo, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow, Polyline } from "@react-google-maps/api";

const libraries = ["places"];

function GoogleMapComponent({ paths, mapCenter, initialZoom, mechanicData, customerData }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  const center = useMemo(() => ({ lat: mapCenter.latitude, lng: mapCenter.longitude }), [mapCenter.latitude, mapCenter.longitude]);
  const [zoom, setZoom] = useState(initialZoom);
  const [selectedCustomer, setSelectedCustomer] = useState(false);

  useEffect(() => {
    setZoom(initialZoom);
  }, [mechanicData, initialZoom]);

  if (!isLoaded) return <div>Loading...</div>;

  const polylineOptions = {
    strokeColor: '#4a80f5',
    strokeOpacity: 0,
    strokeWeight: 4,
    icons: [
      {
        icon: { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 4 }, 
        offset: '0',
        repeat: '20px',
      },
    ]
  };

  return (
    <GoogleMap
      zoom={zoom}
      center={center}
      mapContainerClassName="map-container"
    >
      {paths && paths.map(([pointA, pointB], index) => (
        <Polyline key={index} path={[pointA, pointB]} options={polylineOptions} />
      ))}

      {mechanicData && (
        <Marker
          position={{ lat: parseFloat(mechanicData.latitude), lng: parseFloat(mechanicData.longitude) }}
          icon={{
            url: 'https://ridenrepair.com/mechanic_marker.png',
            scaledSize: new window.google.maps.Size(85, 70),
          }}
        />
      )}

      {customerData && (
        <Marker
          position={{ lat: parseFloat(customerData.latitude), lng: parseFloat(customerData.longitude) }}
          icon={{
            url: 'https://ridenrepair.com/customer_marker.png',
            scaledSize: new window.google.maps.Size(30, 50),
          }}
          onClick={() => setSelectedCustomer(true)}
        />
      )}

      {customerData && selectedCustomer && (
        <InfoWindow
          position={{ lat: parseFloat(customerData.latitude), lng: parseFloat(customerData.longitude) }}
          onCloseClick={() => setSelectedCustomer(false)}
        >
          <div>
            <p>Booking ID: {customerData.booking_id}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export default GoogleMapComponent;
