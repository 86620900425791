import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import axios from 'axios';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AvailableCitiesModal from '../elements/available-cities-modal';
import Modal from '../elements/modal';

// Images
import bnrImg from "../../images/banner/img_contactus.avif";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      message: '',
      emailError: '',
      emptyMessageError: '',
      phoneError: '',
      isClient: false,
      isMobileApp: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
  }

  componentDidMount() {
    this.setState({ isClient: true });

    // Open modal if phone not provided or ignored
    const { setOpenModal } = this.props;
    if (localStorage.getItem('phone_provided') !== 'yes' && sessionStorage.getItem('phone_ignored') !== 'yes') {
      setOpenModal(true);
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  async handleSendMessage(event) {
    event.preventDefault();
    const { name, email, phone, message } = this.state;
    const { history } = this.props;

    let valid = true;
    this.setState({ emailError: '', phoneError: '', emptyMessageError: '' });

    if (!this.validateEmail(email)) {
      this.setState({ emailError: 'Please enter a correct email address' });
      valid = false;
    }

    if (!this.validatePhone(phone)) {
      this.setState({ phoneError: 'Please enter a correct phone number' });
      valid = false;
    }

    if (message.trim() === "") {
      this.setState({ emptyMessageError: 'No message provided, kindly enter a detailed description of the questions you may have.' });
      valid = false;
    }

    if (!valid) return;

    const data = { name, email, phone, message };

    try {
      const response = await axios.post("https://ridenrepair.com/api/sendemail", data);
      if (response.status === 200) {
        history.push('/thank-you-email');
      } else {
        console.error("Email subscribing error", response);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  }

  validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email);
  }

  validatePhone(phone) {
    const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    return phoneRegex.test(phone);
  }

  render() {
    const { isMobileApp } = this.props;
    const { name, email, phone, message, emailError, phoneError, emptyMessageError } = this.state;
    const { citiesModal, setCitiesModal, openModal, setOpenModal, user, setUser } = this.props;
    const canonicalUrl = 'https://www.ridenrepair.com/contact';

    return (
      <>
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
          <title>Contact - Ride N Repair</title>
          <meta
            name="description"
            content="Get in touch with Ride N Repair for all your bike service and repair inquiries. Reach out via phone, email, or visit our office location. We're here to help!"
          />
          <meta
            name="keywords"
            content="Contact Ride N Repair, Phone, Email, Location, Bike Service, Two-wheeler Repair, Support"
          />
        </Helmet>

        {citiesModal && <AvailableCitiesModal closeModal={() => setCitiesModal(false)} />}

        {/* {!isMobileApp && openModal && <Modal openModal={openModal} setOpenModal={setOpenModal} user={user} setUser={setUser} />} */}

        <Header user={user} setUser={setUser} />

        <div className="page-content bg-white">
          <div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bnrImg})` }}>
            <div className="container">
              <div className="page-banner-entry text-center">
                <h1><span>Contact Us</span></h1>
                <nav aria-label="breadcrumb" className="breadcrumb-row">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="heading-bx mb-20">
                <h6 className="title-ext text-primary">Contact Info</h6>
                <h2 className="title mb-0">CONTACT US</h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="icon-box">
                    <h6 className="title"><i className="ti-map-alt" aria-hidden="true"></i>Location</h6>
                    <button onClick={() => setCitiesModal(true)}>CITIES WE SERVE</button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                  <div className="icon-box">
                    <h6 className="title"><i className="ti-id-badge" aria-hidden="true"></i>Email & Phone Support</h6>
                    <div
                      className="icon-content"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (typeof window !== 'undefined') {
                          window.location.href = 'mailto:info@ridenrepair.com';
                        }
                      }}
                    >
                      <p>info@ridenrepair.com</p>
                    </div>
                    <a href="tel:08031406663"><p>8031406663</p></a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="icon-box">
                    <h6 className="title"><i className="ti-world" aria-hidden="true"></i>Follow Us</h6>
                    <ul className="social-media">
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/weridenrepair"
                          rel="noreferrer"
                          className="btn btn-primary"
                          aria-label="Facebook"
                        >
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/weridenrepair"
                          rel="noreferrer"
                          className="btn btn-primary"
                          aria-label="Instagram"
                        >
                          <i className="fa fa-instagram" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/company/ride-n-repair/"
                          rel="noreferrer"
                          className="btn btn-primary"
                          aria-label="LinkedIn"
                        >
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UC8RXTAzOW0SRX7whOyK-yJg"
                          rel="noreferrer"
                          className="btn btn-primary"
                          aria-label="YouTube"
                        >
                          <i className="fa fa-youtube" aria-hidden="true"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div className="section-area">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.905799118166!2d77.6266947766322!3d12.913775716140208!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15b62cc155a3%3A0xd0cca3178e6c0dc0!2sRide%20N%20Repair!5e0!3m2!1sen!2sus!4v1705098441199!5m2!1sen!2sus"
              className="align-self-stretch d-flex"
              style={{ width: "100%", minHeight: "450px" }}
              title="Ride N Repair Location"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div> */}

          <section className="section-area section-sp2">
            <div className="container">
              <div className="heading-bx mb-20">
                <h6 className="title-ext text-primary">Contact Form</h6>
                <h2 className="title mb-0">DO YOU HAVE ANY QUESTIONS?</h2>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <form className="contact-form" onSubmit={this.handleSendMessage} noValidate>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="name"
                              type="text"
                              id="name"
                              className="form-control valid-character"
                              placeholder="Your Name"
                              value={name}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="email"
                              type="email"
                              id="email"
                              className="form-control"
                              placeholder="Your Email Address"
                              value={email}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              name="phone"
                              type="text"
                              id="phone"
                              className="form-control int-value"
                              placeholder="Your Phone"
                              value={phone}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="input-group">
                            <textarea
                              name="message"
                              id="message"
                              rows="4"
                              className="form-control"
                              placeholder="Type Message"
                              value={message}
                              onChange={this.handleChange}
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {emailError && <p className="error-message" aria-live="polite">{emailError}</p>}
                        {phoneError && <p className="error-message" aria-live="polite">{phoneError}</p>}
                        {emptyMessageError && <p className="error-message" aria-live="polite">{emptyMessageError}</p>}
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="btn btn-primary btn-lg"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer setCitiesModal={setCitiesModal} />
      </>
    );
  }
}

export default ContactUs;
