import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import bg2 from "../../images/background/bg1.png";

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ScheduleServiceSection from "../elements/schedule-service";
import PricingTableSection from "../elements/pricing-table";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";
import AvailableCitiesModal from '../elements/available-cities-modal';

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class RefundPolicy extends Component{

	state = {
    isClient: false,
  };

	componentDidMount() {
		this.setState({ isClient: true });
	}
	
	render(){

		const { isClient } = this.state;

		return (
			<>
				{this.props.citiesModal && <AvailableCitiesModal closeModal={() => this.props.setCitiesModal(false)} />}
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={ isClient ? { backgroundImage: "url("+bnrImg+")" } : {} }>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Refund Policy</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Refund Policy</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white" style={ isClient ? { backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat" } : {} }>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h4 className="title-ext text-primary">Refund Policy</h4>
									<p>
                    Refund Policy for RIDE N REPAIR (Ride N Repair Pvt Ltd)

                    At RIDE N REPAIR (Ride N Repair Pvt Ltd), we strive to provide high-quality services and customer satisfaction. In the event that a customer is not satisfied with their service, the following Refund Policy outlines the process for obtaining a refund.

                    Timeframe for Refund Requests Customers may request a refund within 2 days of the completed service. Refund requests made after this timeframe may not be honored.
                    Process for Requesting a Refund Customers who wish to request a refund must contact Bike Service Company at 8031406663 or info@ridenrepair.com and provide a detailed explanation of the reason for their request. The Company will review the request and determine if a refund is appropriate.
                    Method of Refund Refunds will be provided using the same method of payment as the original transaction, unless otherwise agreed upon by the customer and the Company.
                    Limitations The Company reserves the right to limit the amount of the refund, if applicable, based on the specific circumstances of each case.
                    Contact Information For questions or concerns about this Refund Policy or to request a refund, please contact us at 8031406663 or info@ridenrepair.com

                    This Refund Policy is effective as of 6 Feb 2023.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default RefundPolicy;