import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Images
import pic6 from "../../images/about/optimized_chooseus.webp";

class ChooseUsSection extends Component {
  render() {
    const { cityKey } = this.props;

    let formattedCityKey = '';

    if (cityKey) {
      formattedCityKey = cityKey
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    return (
      <>
        <section className="section-area bg-white section-sp1">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30">
                <div className="choose-media">
                  <LazyLoadImage
                    src={pic6}
                    alt="Why choose Ride N Repair"
                    effect="blur"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="heading-bx mb-30">
                  <h2 className="title mb-0">WHY CHOOSE US</h2>
                  <p>
                    We offer a full range of car and bike services at your doorstep{' '}
                    {cityKey && `in ${formattedCityKey}`}.
                    All services are performed by highly qualified mechanics.
                    We also assign a committed service buddy during the time of service to ensure complete service satisfaction.
                  </p>
                </div>
                <div className="progress-area">
                  {/* Progress bars can be optimized or converted into components */}
                  <div className="progress-box style1">
                    <div className="progress-info">
                      <h6 className="title">Quality Services</h6>
                      <h6 className="progress-value">95%</h6>
                    </div>
                    <div className="progress">
                      <div className="progress-bar bg-primary" style={{ width: '96%' }}></div>
                    </div>
                  </div>
                  <div className="progress-box style1">
                    <div className="progress-info">
                      <h6 className="title">Experienced Mechanics</h6>
                      <h6 className="progress-value">85%</h6>
                    </div>
                    <div className="progress">
                      <div className="progress-bar bg-primary" style={{ width: '100%' }}></div>
                    </div>
                  </div>
                  <div className="progress-box style1">
                    <div className="progress-info">
                      <h6 className="title">On-Time Doorstep Service</h6>
                      <h6 className="progress-value">90%</h6>
                    </div>
                    <div className="progress">
                      <div className="progress-bar bg-primary" style={{ width: '98%' }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ChooseUsSection;
