import React, { Component } from 'react';
import cityContent from '../../data/city_content.json'; // Adjust the path as necessary
import '../../css/introduction.css'; // Specific CSS for styling

class Introduction extends Component {
  render() {
    const { cityKey } = this.props;

    let formattedCityKey = '';

    if (cityKey) {
      formattedCityKey = cityKey
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    const cityData = cityContent[cityKey] || {
      intro:
        "Welcome to Ride N Repair, where we provide expert vehicle repair services across multiple cities. Trust us for timely and cost-effective solutions for all your repair needs.",
    };

    const { intro } = cityData;

    return (
      <>
        <section className="introduction-section">
          <div className="container">
            <div className="heading-bx text-center">
              <h2>Introduction</h2>
            </div>
            <div className="content">
              <span
                className="intro-text"
                dangerouslySetInnerHTML={{ __html: intro }}
              />
              <p className="city-text">
                {cityKey && `Currently serving in ${formattedCityKey}.`}
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Introduction;
