import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../css/available-cities-modal.css';
import { cities } from './cities-data';
import indiaFlag from "../../images/cities/india_flag.png";

class AvailableCitiesModal extends Component {
  constructor(props) {
    super(props);
    this.createSlug = this.createSlug.bind(this);
  }
  
  /**
   * Converts a city name into a URL-friendly slug.
   * Example: "New Delhi" -> "new-delhi"
   * @param {string} title - The city name.
   * @returns {string} - The slugified city name.
   */
  createSlug(title) {
    return title
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')       // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '')    // Remove all non-word characters except hyphens
      .replace(/--+/g, '-')       // Replace multiple hyphens with a single hyphen
      .replace(/^-+|-+$/g, '');   // Remove leading and trailing hyphens
  }

  render() {
    const { closeModal } = this.props;
    const sortedCities = cities.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="cities-modal">
        <div className="india-header">
          <img src={indiaFlag} alt="India Flag" className="india-flag" loading="lazy"/>&nbsp;&nbsp;
          <h4 className='india-header'>INDIA</h4>
        </div>
        <a class="modal-popup__close" href="#" onClick={this.props.closeModal}>X</a>
        <div className="city-list">
          {sortedCities.map((city) => (
            <div key={city.key} className="city-item">
              {/* <img src={city.image} alt={city.name} /> */}
              {/* <span>{city.name}</span> */}
              <Link
                  to={`/city/${this.createSlug(city.name)}`}
                  onClick={closeModal}
                  className="city-link"
                  aria-label={`Navigate to ${city.name} page`}
                >
                  {city.name}
                </Link>
            </div>
          ))}
        </div>
        <div className="close-button-row">
          <button onClick={this.props.closeModal}>Close</button>
        </div>
      </div>
    );
  }
}

export default AvailableCitiesModal;


// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import '../../css/available-cities-modal.css';
// import { cities } from './cities-data';
// import indiaFlag from "../../images/cities/india_flag.png";

// class AvailableCitiesModal extends Component {
//   constructor(props) {
//     super(props);
//     this.createSlug = this.createSlug.bind(this);
//   }

//   /**
//    * Converts a city name into a URL-friendly slug.
//    * Example: "New Delhi" -> "new-delhi"
//    * @param {string} title - The city name.
//    * @returns {string} - The slugified city name.
//    */
//   createSlug(title) {
//     return title
//       .toLowerCase()
//       .trim()
//       .replace(/\s+/g, '-')       // Replace spaces with hyphens
//       .replace(/[^\w-]+/g, '')    // Remove all non-word characters except hyphens
//       .replace(/--+/g, '-')       // Replace multiple hyphens with a single hyphen
//       .replace(/^-+|-+$/g, '');   // Remove leading and trailing hyphens
//   }

//   render() {
//     const { closeModal } = this.props;
//     const sortedCities = [...cities].sort((a, b) => a.name.localeCompare(b.name));

//     return (
//       <div className="cities-modal" role="dialog" aria-modal="true" aria-labelledby="cities-modal-title">
//         <div className="modal-header">
//           <div className="india-header">
//             <img
//               src={indiaFlag}
//               alt="India Flag"
//               className="india-flag"
//               loading="lazy"
//             />
//             <h4 id="cities-modal-title" className='india-header'>INDIA</h4>
//           </div>
//           <button
//             type="button"
//             className="modal-popup__close"
//             aria-label="Close Cities Modal"
//             onClick={closeModal}
//           >
//             &times;
//           </button>
//         </div>

//         <div className="city-list">
//           <ul>
//             {sortedCities.map((city) => (
//               <li key={city.key} className="city-item">
//                 <Link
//                   to={`/city/${this.createSlug(city.name)}`}
//                   onClick={closeModal}
//                   className="city-link"
//                   aria-label={`Navigate to ${city.name} page`}
//                 >
//                   {city.name}
//                 </Link>
//               </li>
//             ))}
//           </ul>
//         </div>

//         <div className="close-button-row">
//           <button
//             type="button"
//             className="btn btn-secondary close-button"
//             onClick={closeModal}
//             aria-label="Close Cities Modal"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     );
//   }
// }

// export default AvailableCitiesModal;
