import React, { Component } from 'react';
import Slider from 'react-slick';

// Import Images
import bg1 from "../../images/background/optimized_clientssay.webp";

// Team Content
const content = [
  {
    name: "Saurabh Mehta",
    text: "I recently had my Honda Activa serviced at Ride N Repair and I couldn't be happier with the service I received. The mechanic was friendly and knowledgeable, taking the time to explain the necessary work and answer all my questions. The service was completed promptly. Highly recommend Ride N Repair for all your two-wheeler needs.",
  },
  {
    name: "Anita Sharma",
    text: "Ride N Repair did an excellent job fixing my Royal Enfield. The team was professional and efficient, ensuring every detail was taken care of. They kept me informed throughout the process and completed the service ahead of schedule. I’m very satisfied and will definitely return for future maintenance.",
  },
  {
    name: "Rahul Verma",
    text: "Had my Suzuki Access 125 serviced at Ride N Repair. The staff was courteous and the mechanic was very knowledgeable. They identified and fixed issues that I wasn't even aware of. The pricing was transparent and fair. Great experience overall!",
  },
  {
    name: "Priya Kapoor",
    text: "I took my Yamaha Fascino to Ride N Repair for a routine check-up. The service was thorough, and the team was very attentive to my concerns. They provided valuable tips to keep my bike in top condition. Fast and reliable service – highly recommended!",
  },
  {
    name: "Deepak Joshi",
    text: "Ride N Repair exceeded my expectations when servicing my KTM Duke. The mechanics are skilled and passionate about their work. They ensured my bike was in perfect condition and even gave it a detailed cleaning. Excellent customer service!",
  },
  {
    name: "Neha Gupta",
    text: "Fantastic service at Ride N Repair! I had issues with my TVS Apache and the team quickly diagnosed and fixed the problem. They were honest and didn’t try to upsell unnecessary services. Very trustworthy and efficient.",
  },
  {
    name: "Vikram Singh",
    text: "Ride N Repair provided top-notch service for my Bajaj Pulsar. The mechanics were thorough and made sure everything was functioning perfectly before handing it back to me. The waiting area was comfortable, and the staff was friendly. Will definitely return!",
  },
  {
    name: "Meena Reddy",
    text: "I’m extremely pleased with the service I received at Ride N Repair for my Honda Dio. The team was professional, and the service was completed on time. They even provided a follow-up to ensure everything was working fine. Highly dependable!",
  },
  {
    name: "Amit Bhatt",
    text: "Ride N Repair is my go-to place for all two-wheeler maintenance. Recently, they serviced my Hero Splendor and did an outstanding job. The mechanics are honest, skilled, and the pricing is competitive. Great place to trust your bike with!",
  },
  {
    name: "Shruti Malhotra",
    text: "I had a wonderful experience with Ride N Repair when servicing my Suzuki Gixxer. The team was efficient, friendly, and very knowledgeable. They took the time to explain the issues and the solutions clearly. Excellent service and customer care!",
  },
];


class TestimonialSection extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    return (
      <>
        <section
          className="section-area section-sp2 bg-gray ovbl-dark testimonial-area"
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
              <div className="clearfix mb-2">
                <h6 className="title-ext text-primary">TESTIMONIAL</h6>
                <h2 className="title mb-0">WHAT CLIENTS SAY</h2>
              </div>
            </div>
            <Slider {...settings} className="testimonial-carousel-1 slick-arrow-none">
              {content.map((item, index) => (
                <div className="slider-item" key={index}>
                  <div className="testimonial-bx">
                    <div className="testimonial-content">
                      <p>{item.text}</p>
                    </div>
                    <div className="client-info">
                      <div className="testimonial-info">
                        <h6 className="name">- {item.name}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    );
  }
}

export default TestimonialSection;
