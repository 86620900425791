import React, { Component } from "react";
import axios from 'axios';
import GoogleMapComponent from "./track-booking";
import { format } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import Header from "../layout/header";
import '../../css/booking-details.css';

class BookingDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingDetails: null,
      selectedMarker: null,
      isLoaded: false,
      paymentMessage: '',
      photos: []
    };
    this.handlePay = this.handlePay.bind(this);
  }

  componentDidMount() {
    this.loadScriptGoogleMaps();
    this.fetchBookingDetails();
    this.fetchBookingPhotos();
  }

  loadScriptGoogleMaps() {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      this.setState({ isLoaded: true });
    };

    document.head.appendChild(script);
  }

  async fetchBookingDetails() {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bookingId = urlSearchParams.get("booking_id");
      const actualBookingId = urlSearchParams.get("actual_booking_id");
      let bookingDetails;

      if (bookingId) {
        bookingDetails = await axios.get(`https://ridenrepair.com/api/get_booking_via_booking_id?booking_id=${bookingId}`);
        this.setState({ bookingDetails: bookingDetails.data });
      } else if (actualBookingId) {
        bookingDetails = await axios.get(`https://ridenrepair.com/api/get_booking_via_actual_booking_id?booking_id=${actualBookingId}`);
        this.setState({ bookingDetails: bookingDetails.data });
      } else {
        console.log("Booking ID not found in the URL");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }

  async fetchBookingPhotos() {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const bookingId = urlSearchParams.get("actual_booking_id");

      const response = await axios.get(`https://ridenrepair.com/api/get_booking_photos?booking_id=${bookingId}`);

      if (response.data.success) {
        this.setState({ photos: response.data.photos });
      } else {
        console.log("No photos found for this booking ID");
      }
    } catch (error) {
      console.error("Error fetching photos:", error);
    }
  }

  onSuccessfulPayment = (booking_id, email, amount) => {
    const params = {
      booking_id: booking_id,
      email: email,
      paid_amount: amount,
      order_status: 'COMPLETED'
    }

    try {
      axios.post("https://ridenrepair.com/api/update_booking_paid_amount", params, { headers: { 'Content-Type': 'application/json' } });
    } catch (error) {
      console.log('Error: ', error);
    }

    try {
      axios.post("https://ridenrepair.com/api/update_booking_status", params, { headers: { 'Content-Type': 'application/json' } });
    } catch (error) {
      console.log('Error: ', error);
    }

    this.setState({ paymentMessage: 'Paid Successfully' });
	}

  loadScript = (src) => {
		return new Promise((resolve) => {
			const script = document.createElement("script");
			script.src = src;
			script.onload = () => {
				resolve(true);
			};
			script.onerror = () => {
				resolve(false);
			};
			document.body.appendChild(script);
		});
	}

  async handlePay (payAmount) {
    try {
      const { booking_id, email, name, phone } = this.state.bookingDetails;
      const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
    
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: payAmount * 100, currency: 'INR' })
      };

      const data = await fetch('https://ridenrepair.com/api/razorpay', requestOptions).then(
        (response) => response.json()
      )

      const options = {
        key: 'rzp_live_v9Y9ZhDUWFgjNI',
        // key: 'rzp_live_JiAxnDUo1qXebr', // Enter the Key ID generated from the Dashboard
        // key: 'rzp_test_K9AJpedBWJr6ah',
        amount: payAmount,
        currency: data.currency,
        name: 'Ride N Repair',
        description: 'Service Transaction',
          image: 'https://ridenrepair.com/api/logo.png',
          order_id: data.id,
          handler: (response) => {this.onSuccessfulPayment(booking_id, email, parseFloat(data.amount) / 100); },
          prefill: {
            name: name || 'User',
            email: email,
            contact: phone,
          },
          notes: {
            booking_id: booking_id,
          },
          theme: {
            color: "#61dafb",
          },
      };
      console.log(options)
      
      if (typeof window !== 'undefined') {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    } catch (error) {
      console.log("Error occurred while processing payment:", error);
    }
  }

  render() {
    const { bookingDetails, isLoaded } = this.state;
    const indiaTimezone = 'Asia/Kolkata';
    const dateFormat = 'yyyy-MM-dd';
    const formattedDate = format(new Date(), dateFormat, { timeZone: indiaTimezone });

    if (!isLoaded) {
      return (
        <div className="booking-history-parent">
          <Header/>
          <div className="booking-history">
            <p>Loading...</p>
          </div>
        </div>
      );
    }

    if (!bookingDetails) {
      console.log("No booking details available.");
      return (
        <div className="booking-history-parent">
          <Header/>
          <div className="booking-history">
            <p>No data available.</p>
          </div>
        </div>
      );
    }

    if (bookingDetails.order_status === 'COMPLETED' || bookingDetails.order_status === 'CASH TAKEN' || bookingDetails.order_status === 'CANCELLED' || bookingDetails.order_status === 'DUPLICATE' || bookingDetails.date_of_service < formattedDate) {
      return (
        <div className="booking-history-parent">
          <Header />
          <div className="booking-history">
            <p>Booking status doesn't require further action.</p>
          </div>
        </div>
      );
    }

    const dateOfService = new Date(bookingDetails.date_of_service);
    const timeOfService = new Date(`1970-01-01T${bookingDetails.time_of_service}Z`);

    return (
      <div>
        <Header/>
        {/* <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Link to="/">
            <img src="https://ridenrepair.com/full_logo.png" alt="Ride N Repair Logo" style={{ width: '150px', height: 'auto' }} />
          </Link>
        </div> */}

        <GoogleMapComponent
          paths={[
            [
              { lat: parseFloat(bookingDetails.latitude), lng: parseFloat(bookingDetails.longitude) },
              { lat: parseFloat(bookingDetails.mechanicLatitude), lng: parseFloat(bookingDetails.mechanicLongitude) },
            ],
          ]}
          mapCenter={{ latitude: parseFloat(bookingDetails.latitude), longitude: parseFloat(bookingDetails.longitude) }}
          initialZoom={14}
          mechanicData={{
            latitude: parseFloat(bookingDetails.mechanicLatitude),
            longitude: parseFloat(bookingDetails.mechanicLongitude),
          }}
          customerData={{
            latitude: parseFloat(bookingDetails.latitude),
            longitude: parseFloat(bookingDetails.longitude),
            booking_id: bookingDetails.booking_id,
          }}
        />

        <div style={{ margin: '20px', padding: '20px', border: '1px solid #ccc' }}>
          <h2>Booking Details</h2>
          <p>Booking ID: {bookingDetails.booking_id}</p>
          <p>Vehicle Name: {bookingDetails.vehicle_name}</p>
          <p>Date of Service: {dateOfService.toDateString()}</p>
          <p>Time of Service: {timeOfService.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          <p>Type of Service: {bookingDetails.service_type}</p>
          {bookingDetails.final_payment_amount && (
            <>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>Charge Description</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingDetails.charges_desc.split(',').map((desc, index) => (
                    <tr key={index}>
                      <td>{desc}</td>
                      <td>Rs. {bookingDetails.amount_details.split(',')[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <br/>
              <button onClick={() => this.handlePay(parseFloat(bookingDetails.final_payment_amount))}>Pay Rs. {bookingDetails.final_payment_amount}</button>
            </>
          )}
          {!bookingDetails.final_payment_amount &&
            <p>Total Amount: {bookingDetails.total_amount}</p>
          }
          <br/>
          {this.state.paymentMessage && <p>{this.state.paymentMessage}</p>}
          <br/>
          <button>
            <a href='tel:08031406663' style={{ textDecoration: 'underline' }}>
              <h5 style={{ color: 'white' }}>Call Mechanic</h5>
            </a>
          </button>
        </div>

        { this.state.photos.length > 0 && <div>
          <h2>Photos</h2>
          <div className="photo-gallery" style={{ textAlign: 'center' }}>
            {this.state.photos.map((photo, index) => (
              <img key={index} src={photo} alt={`Photo ${index + 1}`} style={{ maxWidth: '300px', maxHeight: '300px' }} />
            ))}
          </div>
        </div>
        }
      </div>
    );
  }
}

export default BookingDetails;
