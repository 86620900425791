import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom'; // Import withRouter
import '../../css/booking-history.css';
import Header from "../layout/header";
import { format } from 'date-fns-tz';

const BookingHistory = ({ user, history }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [invoiceMessage, setInvoiceMessage] = useState('');
  const [bookingStates, setBookingStates] = useState({});

  useEffect(() => {
    // Fetch bookings data
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`https://ridenrepair.com/api/get_booking_via_phone?phone=${user.ph}`);
        const data = response.data;
        setBookings(Array.isArray(data) ? data : []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [user.ph]);

  const updateBookingState = (bookingId, newState) => {
    setBookingStates(prevState => ({
      ...prevState,
      [bookingId]: newState
    }));
  };

  // Function to send invoice for a specific booking
  const sendInvoice = async (booking_id, email, charges_desc, amount_details, invoice_count) => {
    try {
      await axios.post('https://ridenrepair.com/api/send_invoice', {
        booking_id: booking_id,
        email: email,
        charges_desc: charges_desc,
        amount_details: amount_details,
        invoice_count: invoice_count
      });
      updateBookingState(booking_id, { message: 'Invoice sent successfully.', isDisabled: true });
    } catch (error) {
      updateBookingState(booking_id, { message: error.response?.data?.message || 'Failed to send invoice.', isDisabled: false });
      console.error('Error:', error);
    }

    // Reset message and enable button after 5 seconds
    setTimeout(() => {
      updateBookingState(booking_id, { message: '', isDisabled: false });
    }, 5000);
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className='booking-history-parent'>
      <Header/>
      <div className="booking-history">
        <h1>Service History</h1>
        {bookings.length > 0 ? bookings.map((booking, index) => (
          <div key={index} className="booking">
            <h2>{booking.vehicle_name}</h2>
            <p><strong>Service Type:</strong> {booking.service_type}</p>
            <p><strong>Service Date:</strong> {booking.date_of_service}</p>
            <p><strong>Time:</strong> {booking.time_of_service}</p>
            {(booking.final_payment_amount) && (
              <p><strong>Price:</strong> Rs. {booking.final_payment_amount}</p>
            )}
            {format(new Date(), 'yyyy-MM-dd', { timeZone: 'Asia/Kolkata' }) === booking.date_of_service && booking.order_status !== 'CANCELLED' && booking.order_status !== 'DUPLICATE' && booking.order_status !== 'COMPLETED' && booking.order_status !== 'CASH TAKEN' && booking.mechanic_number !== '' && booking.mechanic_number !== undefined && (
              <button 
                onClick={async (e) => {
                  e.preventDefault();
                  history.push({
                    pathname: '/booking-details',
                    search: `actual_booking_id=${booking.booking_id}`
                  });
                }}
              >
                Track your booking
              </button>
            )}
            {(booking.order_status === 'COMPLETED' || booking.order_status === 'CASH TAKEN') && (booking.invoice_count || 0) < 3 && (
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  await sendInvoice(booking.booking_id, user.email, booking.charges_desc, booking.amount_details, booking.invoice_count || 0);
                }}
                disabled={bookingStates[booking.booking_id]?.isDisabled || false}
                className={bookingStates[booking.booking_id]?.isDisabled ? 'disabled-button' : ''}
              >
                Send Invoice
              </button>
            )}
            {bookingStates[booking.booking_id]?.message && <p>{bookingStates[booking.booking_id]?.message}</p>}
            <details>
              <summary>Service Details</summary>
              {booking.charges_desc && booking.amount_details ? (
                <ul>
                  {booking.charges_desc.split(',').map((desc, idx) => (
                    <li key={idx}>
                      <span className="charge-desc">{desc.trim()}</span> - 
                      <span className="charge-amount"> Rs. {booking.amount_details.split(',')[idx].trim()}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No details available.</p>
              )}
            </details>
          </div>
        )): (<p>No service history available.</p>)}
      </div>
    </div>
  );
};

export default withRouter(BookingHistory); // Wrap with withRouter to access history prop
