// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.scss';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import TagManager from 'react-gtm-module';
// import ReactPixel from 'react-facebook-pixel';
// import { Helmet } from "react-helmet";

// const tagManagerArgs = {
//   gtmId: 'GTM-KHQHKXZ'
// }

// const fbPixelAdvancedMatching = {}

// const fbPixelOptions = {
//   autoConfig: true,
//   debug: false,
// };

// const fbPixelId = '622764776351083';

// TagManager.initialize(tagManagerArgs);

// ReactPixel.init(fbPixelId, fbPixelAdvancedMatching, fbPixelOptions);
// ReactPixel.pageView();

// // const mountElement = document.getElementById('root');
// // const reactMountFn = (mountElement.childElementCount === 0)
// //     ? ReactDOM.render
// //     : ReactDOM.hydrate;
// // reactMountFn(<App />, mountElement);

// ReactDOM.hydrate(
//     <App />,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Hydrate the App component
ReactDOM.hydrate(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
