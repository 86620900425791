import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../images/logo.png";
import "react-datepicker/dist/react-datepicker.css";


class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			phone: '',
			alternatePhone: '',
			email: '',
			phoneError: '',
			emailError: '',
			first_name: '',
			last_name: '',
			password: '',
			gender: '',
			dob: '',
			emailExists: '',
			disabledSendEmailOTP: false,
			disabledSendPhoneOTP: false,
			emailCountdown: 10,
			phoneCountdown: 10,
			is_email_verified: false,
			is_phone_verified: false,
			email_otp: '',
			phone_otp: '',
			signUpError: '',
			app_source: ''
		};
	}

	componentDidMount() {
    this.props.setShowChatBot(false);
		const isWebView = typeof window !== 'undefined' && window.ReactNativeWebView !== undefined;

		if (isWebView) {
			if (/iPhone|iPod|iPad/.test(window.navigator.userAgent)) {
				this.setState({ app_source: 'ios' });
			} else if (/Android/.test(window.navigator.userAgent)) {
				this.setState({ app_source: 'android' });
			} else if (/Windows/.test(window.navigator.userAgent)) {
				this.setState({ app_source: 'windows' });
			} else {
				this.setState({ app_source: 'unknown' });
			}
		}
		const jsonData = localStorage.getItem('userDetails');
    if (jsonData) {
			const data = JSON.parse(jsonData);
			this.props.setUser({is_logged: true, email: data.email, fn: data.first_name, ln: data.last_name, ph: data.phone});
			this.props.history.push('/booking');
    }
  }

  componentWillUnmount() {
    clearInterval(this.phoneInterval);
    clearInterval(this.emailInterval);
  }

	validatePhone = () => {
		const phoneRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
		const isValidPhone = phoneRegex.test(this.state.phone);
		if (isValidPhone) {
			// phone is valid
			this.setState({ phoneError: '' });
		} else {
			// phone is invalid
			this.setState({ phoneError: 'Invalid Phone Number' });
		}
	}

	validateEmail = () => {
		const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		const isValidEmail = emailRegex.test(this.state.email);
		if (isValidEmail) {
			// email is valid
			this.setState({ emailError: '' });
		} else {
			// email is invalid
			this.setState({ emailError: 'Invalid Email' });
		}
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	handleBlurEmail = () => {
		this.validateEmail();
	}

	handleBlurPhone = () => {
		this.validatePhone();
	}

  updatePhoneCountdown = () => {
    if (this.state.phoneCountdown > 0) {
      this.setState(prevState => ({ phoneCountdown: prevState.phoneCountdown - 1 }));
    } else {
      clearInterval(this.phoneInterval);
      this.setState({ phoneCountdown: 10, disabledSendPhoneOTP: false });
    }
  };

  updateEmailCountdown = () => {
    if (this.state.emailCountdown > 0) {
      this.setState(prevState => ({ emailCountdown: prevState.emailCountdown - 1 }));
    } else {
      clearInterval(this.emailInterval);
      this.setState({ emailCountdown: 10, disabledSendEmailOTP: false });
    }
  };

	handleBlurEmailOTP = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		const params = {
			email: this.state.email,
			email_otp: event.target.value
		}
		try {
			axios.post("https://ridenrepair.com/api/validate_email_otp", params, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				if (res.status === 200) {
					if (res.data.is_valid) {
						this.setState({ is_email_verified: true, emailError: '' });
					} else {
						this.setState({ emailError: 'Incorrect Email OTP' })
					}
				}
			});
		} catch (error) {
			console.log('Unable to validate OTP');
		}
	}

  handleBlurPhoneOTP = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		const params = {
			phone: this.state.phone,
			booking_otp: event.target.value
		}
		try {
			axios.post("https://ridenrepair.com/api/validate_booking_otp", params, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				if (res.status === 200) {
					if (res.data.is_valid) {
						this.setState({ is_phone_verified: true, phoneError: '' });
					} else {
						this.setState({ phoneError: 'Incorrect Phone OTP' })
					}
				}
			});
		} catch (error) {
			console.log('Unable to validate OTP');
		}
	}

	sendPhoneOTP = (event) => {
    event.preventDefault();
    this.setState({ disabledSendPhoneOTP: true });
    this.phoneInterval = setInterval(this.updatePhoneCountdown, 1000);
		
		const params = {
			phone: this.state.phone
		}

		axios.post("https://ridenrepair.com/api/send_booking_otp_sms", params);
	}

	sendEmailOTP = (event) => {
    event.preventDefault();
    this.setState({ disabledSendEmailOTP: true });
    this.emailInterval = setInterval(this.updateEmailCountdown, 1000);
		
		const params = {
			email: this.state.email
		}

		axios.post("https://ridenrepair.com/api/send_booking_otp_email", params);
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		const data = {
			phone: this.state.phone,
			email: this.state.email,
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			app_source: this.state.app_source
		}
		if (this.state.is_email_verified && this.state.is_phone_verified) {
			axios.post("https://ridenrepair.com/api/insert_user_profile", data)
				.then(response => {
					const jsonData = JSON.stringify(data);
					localStorage.setItem('userDetails', jsonData);
					localStorage.setItem('userPhone', this.state.phone);
					localStorage.setItem('userEmail', this.state.email);
					this.props.setUser({is_logged: true, email: data.email, fn: data.first_name, ln: data.last_name, ph: data.phone});
					this.props.history.push('/booking');
				})
				.catch(error => {
					this.setState({ signUpError: 'Unable to Sign Up' });
				});
		}
	}

	render() {
		const { 
			signUpError,
			emailError,
			phoneError,
			is_email_verified, 
			is_phone_verified, 
			disabledSendEmailOTP, 
			disabledSendPhoneOTP, 
			emailCountdown, 
			phoneCountdown, 
			email_otp,
			phone_otp,
			first_name,
			last_name,
			phone,
			email
		} = this.state;
	


		return (
			<>
				<div className="page-wraper account-wraper bg-gray py-100 px-20" style={{ overflowY: 'auto' }}>
					<div className="my-account">
						<div className="logo" >
							<Link to="">
							<img src={logo} alt="" />
							</Link>
						</div>
						<form>
              <div className="form-group">
                <center><p>Sign Up</p></center>
              </div>
							<div className="form-group">
								<input type="text" name="first_name" className="form-control" placeholder="First Name" value={first_name} onChange={this.handleChange} />
							</div>
							<div className="form-group">
								<input type="text" name="last_name" className="form-control" placeholder="Last Name" value={last_name} onChange={this.handleChange} />
							</div>
              <div className="form-group">
                <input type="number" name='phone' className="form-control" placeholder="Phone (only enter the 10 digits)" value={phone} onChange={this.handleChange} onBlur={this.handleBlurPhone} />
              </div>
              <div className="form-group">
                <input style={{ border: is_phone_verified ? '2px solid green' : '' }} type="number" className="form-control" name="phone_otp" placeholder="Enter Phone OTP" value={phone_otp} onChange={this.handleChange} onBlur={this.handleBlurPhoneOTP} />
                {disabledSendPhoneOTP ? (
                  <div style={{ marginTop: '4%', color: 'gray' }}>
                    Request another one in {phoneCountdown} seconds...
                  </div>
                ) : (
                  <div style={{ 'cursor': 'pointer', 'text-decoration': 'underline', 'margin-top': '4%' }} onClick={this.sendPhoneOTP}>
                    Send Phone OTP
                  </div>
                )}
                {phoneError && <div className="alert alert-danger">{phoneError}</div>}
              </div>
							<div className="form-group">
								<input type="email" name="email" className="form-control" placeholder="Email (used for invoices)" value={email} onChange={this.handleChange} onBlur={this.handleBlurEmail} />
							</div>
							<div className="form-group">
                <input style={{ border: is_email_verified ? '2px solid green' : '' }} type="number" className="form-control" name="email_otp" placeholder="Enter Email OTP" value={email_otp} onChange={this.handleChange} onBlur={this.handleBlurEmailOTP} />
                {disabledSendEmailOTP ? (
                  <div style={{ marginTop: '4%', color: 'gray' }}>
                    Request another one in {emailCountdown} seconds...
                  </div>
                ) : (
                  <div style={{ 'cursor': 'pointer', 'text-decoration': 'underline', 'margin-top': '4%' }} onClick={this.sendEmailOTP}>
                    Send Email OTP
                  </div>
                )}
                {emailError && <div className="alert alert-danger">{emailError}</div>}
              </div>
							<div className="form-group">
								<button type="button" className="btn btn-primary w-100 radius-xl" onClick={this.handleSubmit}>Register</button>
							</div>
							{signUpError && <div className="alert alert-danger">{signUpError}</div>}
						</form>
					</div>
				</div>
			</>
		);
	}
}
export default Register;
