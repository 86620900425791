import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg1.png";
import aboutPic2 from "../../images/about/optimized_aboutus.webp";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

class AboutUsTwoSection extends Component {
  render() {
    const { isMobileApp, cityKey } = this.props;

    let formattedCityKey = '';

    if (cityKey) {
      formattedCityKey = cityKey
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    return (
      <>
        <section
          className="section-area section-sp1 bg-white"
          style={{ backgroundImage: `url(${bg2})`, backgroundRepeat: 'no-repeat' }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 mb-30 mb-md-50">
                <div className="heading-bx">
                  <h2 className="title">ABOUT US</h2>
                  <h6 className="title-ext text-primary">
                    WE'RE A COMPANY OF TALENTED ENGINEERS & MECHANICS
                  </h6>
                  <p>
                    Welcome to our data-powered company, revolutionizing the two-wheeler industry! At our doorstep, you'll find expert mechanics ready to deliver premium car and bike services{' '}
                    {cityKey && `in ${formattedCityKey}`}.
                    Whether it's your trusty two-wheeler or your family's four-wheeled companion, we've got you covered.
                    <br /><br />
                    At Ride n Repair, we are transforming the way cars and bikes are maintained with our all-inclusive 24*7 Doorstep Bike and Car Repair Service. We bring quality, convenience, and dependability to your doorsteps. Use the simple online booking method for  Doorstep Car/Bike Service on Ride N Repair. We also offer Complete Bike and Car Repair services like Tyre Puncture Repair, Car Dent Repair, Tow Service, Full Body Wash, Engine Oil, AC Repair, Battery Check & Replacement, and so on. For any emergency, Ride N Repair mobile mechanics are just a call away. We provide reliable bike and car repair services all over India, including all major cities like Delhi, Mumbai, Bengaluru, Hyderabad, Chennai, Kolkata, Pune, Ahmedabad, Jaipur, gurugram, Noida, Ghaziabad, Faridabad, Greater Noida, Gurgaon, Bangalore  and many more. Stop going to car repair shops or car garages – we provide mobile service to your location! With Ride n Repair, enjoy easy and convenient Car Service at Doorstep and Bike Repair.
                    <br /><br />
                    Our mission is simple: to provide you with hassle-free, top-quality service whenever you need it. With our team of skilled mechanics, we're dedicated to bringing convenience and excellence to the car and bike service industry. Say goodbye to waiting rooms and long queues—we bring the service to you, right on time and with unmatched quality.
                  </p>
                </div>
                <div className="mt-15 mt-md-0">
                  <Link to="/contact" className="btn btn-primary me-3">
                    Contact Us
                  </Link>
                  <Link
                    to={isMobileApp ? '/register' : '/booking'}
                    className="btn btn-outline-secondary"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="about-imgbox">
                  <LazyLoadImage
                    src={aboutPic2}
                    alt="Image describing how Ride N Repair operates"
                    effect="blur"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AboutUsTwoSection;
