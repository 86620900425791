import React, { useState, useMemo } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "../../scss/pages/_places.scss";

export default function Places({ setLocationAddress, setCity, setStateValue, setZip, setLatitude, setLongitude, isMobileApp }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAvEcAoXc_eUTHDbNWKqOZpmS4FsRBCOG8',
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map setLocationAddress={setLocationAddress} setCity={setCity} setStateValue={setStateValue} setZip={setZip} setLatitude={setLatitude} setLongitude={setLongitude} isMobileApp={isMobileApp}/>;
}

function Map({ setLocationAddress, setCity, setStateValue, setZip, setLatitude, setLongitude, isMobileApp }) {
  const center = useMemo(() => ({ lat: 20.59, lng: 78.96 }), []);
  const [selected, setSelected] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [currentLocationError, setCurrentLocationError] = useState(null);

  const getComponentType = (type, components) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : null;
  }
  
  const getCurrentLocation = async (event) => {
    event.preventDefault();
  
    try {
      if (navigator.geolocation) {
        setCurrentLocationError('Fetching current location...');
        
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            try {
              const { latitude, longitude } = position.coords;
  
              // Fetch address from current location coordinates
              const results = await getGeocode({ location: { lat: latitude, lng: longitude } });
              const { address_components } = results[0];
              const city = getComponentType('locality', address_components);
              const state = getComponentType('administrative_area_level_1', address_components);
              const zip = getComponentType('postal_code', address_components);
  
              setLocationAddress(results[0].formatted_address || '');
              setCity(city);
              setStateValue(state);
              setZip(zip);
              setLatitude(latitude);
              setLongitude(longitude);
              setSelected({ lat: latitude, lng: longitude });
              setZoom(18);
              setCurrentLocationError('');
  
            } catch (error) {
              console.error("Error fetching current location:", error);
              setCurrentLocationError("Error fetching current location");
            }
          },
          (error) => {
            console.error("Error getting current location:", error);
            setCurrentLocationError("Error getting current location");
          },
          { enableHighAccuracy: false, timeout: 15000, maximumAge: 0 }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setCurrentLocationError("Geolocation is not supported by this browser.");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      setCurrentLocationError("Unexpected error occurred");
    }
  };
  

  return (
    <>
        <div className="places-container">
          {!isMobileApp && 
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: '3%' }}>
              <button onClick={(e) => getCurrentLocation(e)}>Use Current Location</button>
            </div>
          }
          {currentLocationError && <p style={{ color: '#f42222' }}>{currentLocationError}</p>}
          <PlacesAutocomplete setSelected={setSelected} setZoom={setZoom} setLocationAddress={setLocationAddress} setCity={setCity} setStateValue={setStateValue} setZip={setZip} setLatitude={setLatitude} setLongitude={setLongitude}/>
        </div>

      <GoogleMap
        zoom={zoom}
        center={selected ? selected : center}
        mapContainerClassName="map-container"
      >
        {selected && <Marker position={selected} />}
      </GoogleMap>
    </>
  );
}

const PlacesAutocomplete = ({ setSelected, setZoom, setLocationAddress, setCity, setStateValue, setZip, setLatitude, setLongitude }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const getComponentType = (type, components) => {
    const component = components.find(comp => comp.types.includes(type));
    return component ? component.long_name : null;
  }
  
  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    const { address_components } = results[0];

    setCity(getComponentType('administrative_area_level_2', address_components) ||
            getComponentType('administrative_area_level_3', address_components) ||
            getComponentType('locality', address_components));

    setStateValue(getComponentType('administrative_area_level_1', address_components));
    setZip('postal_code', address_components);

    setSelected({ lat, lng });
    setZoom(18);

    setLocationAddress(address.toString());
    setLatitude(lat);
    setLongitude(lng);
    
		if (typeof window !== 'undefined') {
      window.dataLayer.push({
        event: 'LocationAddress'
      });
    }
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className="combobox-input"
        placeholder="Kindly provide exact service location"
      />
      <ComboboxPopover className="results-popover">
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};