import React, { Component } from 'react';
import { cities } from './cities-data';
import vehicleData from '../../data/vehicle_data.json';

class PartnerShipsSection extends Component {
  render() {
    // Determine the number of items per row based on screen size
    const itemsPerRow = this.props.isMobileBrowser ? 4 : 10;

    // Combine carBrands and bikeBrands, remove duplicates
    const allBrands = [
      ...new Set([
        ...vehicleData.carBrands.map((brand) => brand.label),
        ...vehicleData.bikeBrands.map((brand) => brand.label),
      ]),
    ];

    // Render list items for cities
    const renderCityItems = cities.map((city, index) => (
      <a
        style={{ padding: '5px' }}
        href={`https://www.ridenrepair.com/city/${city.name.toLowerCase()}`}
        key={index}
      >
        {city.name}
      </a>
    ));

    // Render list items for brands
    const renderBrandItems = allBrands.map((brand, index) => (
      <a style={{ padding: '5px' }} key={index}>
        {brand}
      </a>
    ));

    // Function to chunk an array into smaller arrays
    const chunkArray = (array, size) => {
      const chunkedArr = [];
      for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
      }
      return chunkedArr;
    };

    // Chunk the city and brand items into rows
    const chunkedCityItems = chunkArray(renderCityItems, itemsPerRow);
    const chunkedBrandItems = chunkArray(renderBrandItems, itemsPerRow);

    return (
      <section className="section-area section-sp1 bg-white">
        <div className="row justify-content-center align-items-center">
          <div className="col-xl-5 col-lg-5 text-center">
            <div className="heading-bx mb-4 pr-lg-5">
              <h2 className="title">OUR NETWORK</h2>
              <p>
                We have established partnerships with trusted brands and serve various cities.
              </p>
              <div>
                <h4>Cities We Serve:</h4>
                <ul className="partners-list">
                  {chunkedCityItems.map((row, index) => (
                    <li key={index}>{row}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h4>Brands We Serve:</h4>
                <ul className="partners-list">
                  {chunkedBrandItems.map((row, index) => (
                    <li key={index}>{row}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PartnerShipsSection;
