// src/components/LazyGoogleMaps.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../css/lazy-google-maps.css';

const LazyGoogleMaps = ({ src, width, height, title }) => {
  const [isMapVisible, setIsMapVisible] = useState(false);

  const handleShowMap = () => {
    setIsMapVisible(true);
  };

  return (
    <div className="lazy-google-maps">
      {!isMapVisible ? (
        <button className="show-map-button" onClick={handleShowMap}>
          Show Map
        </button>
      ) : (
        <iframe
          src={src}
          width={width}
          height={height}
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title={title}
        ></iframe>
      )}
    </div>
  );
};

LazyGoogleMaps.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
};

LazyGoogleMaps.defaultProps = {
  width: '400',
  height: '300',
  title: 'Google Maps Location',
};

export default LazyGoogleMaps;
