import React, { Component } from 'react';

class LogoSchema extends Component {
  render() {
    const schema = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'name': 'Ride N Repair',
      'url': 'https://www.ridenrepair.com',
      'logo': 'https://ridenrepair.com/logo192.png',
    };

    return (
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    );
  }
}

export default LogoSchema;