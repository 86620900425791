import React, { Component } from 'react';
import cityContent from '../../data/city_content.json'; // Adjust the path as necessary
import '../../css/faq-section.css';  // Assuming you'll create a specific CSS file for styling

class FaqSection extends Component {
  render() {
    const { cityKey } = this.props;
    let formattedCityKey = '';

    // Check if cityKey is defined and format it
    if (cityKey) {
      formattedCityKey = cityKey
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    const cityData = cityContent[cityKey] || {
      subheading_faqs: [],
    };

    const { subheading_faqs } = cityData;

    return (
      <>
        <section className="faq-section">
          <div className="container">
            <div className="heading-bx text-center">
              <h2>FAQs for {formattedCityKey}</h2>
            </div>
            <div className="faq-content">
              {subheading_faqs.map((faq, index) => (
                <div key={index} className="faq-item">
                  <h3 className="faq-subheading">{faq.subheading}</h3>
                  <span
                    className="faq-content"
                    dangerouslySetInnerHTML={{ __html: faq.content }}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default FaqSection;
