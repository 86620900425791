import React, {Component} from 'react';
import { Link, useLocation } from 'react-router-dom';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import bg2 from "../../images/background/bg1.png";

// Images
import bnrImg from "../../images/banner/img_aboutbnr.webp";

class ThankYou extends Component{
	
	render(){

		const { params } = this.props.location.state;

		return (
			<>
				
				<Header user={this.props.user} setUser={this.props.setUser} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Thank You</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Home</Link></li>
										<li className="breadcrumb-item active" aria-current="page">Thank You</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1 bg-white" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat"}}>
					{/* <div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h4 className="title-ext text-primary">Thank You</h4>
									<p>
										Thank you {params.} for scheduling the service. Looking forward to making your experience better.
									</p>
								</div>
							</div>
						</div>
					</div> */}
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30 mb-md-50">
								<div className="heading-bx">
									<h4 className="title-ext text-primary">Thank You</h4>
									<p>
										Thank you {params.first_name} for scheduling the service. Looking forward to making your experience better.
									</p>
									<ul>
										<li>Booking ID: {params.booking_id}</li>
										<li>Email: {params.email}</li>
										<li>Name: {params.first_name} {params.last_name}</li>
										<li>Phone: {params.phone}</li>
										<li>Address: {params.address}</li>
										<li>Vehicle: {params.vehicle_name}</li>
										<li>Service Type: {params.service_type}</li>
										<li>Date of Service: {params.date_of_service}</li>
										<li>Time of Service: {params.time_of_service}</li>
										<li>Total Amount: {params.total_amount}</li>
										<li>Advance Paid: {params.paid_amount}</li>
										<li>Additional Details: {params.additional_details}</li>
									</ul>
									<p>You will receive the booking confirmation details via email and WhatsApp as well.</p>
									<p>Please refrain from making any payment directly to our mechanics without receiving a proper invoice. Ride N Repair will not be held liable for any services or warranties for amounts paid without a valid invoice.</p>
								</div>
							</div>
						</div>
					</div>

				</section>
				
				</div>
				
				<Footer setCitiesModal={this.props.setCitiesModal} />
				
			</>
		);
	}
}

export default ThankYou;